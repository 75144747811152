<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <form [formGroup]="addTourForm">
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="form-group col-md-4">
                                                      <label for="title">First Name<span
                                                                  class="required"></span></label>
                                                      <input type="text" class="form-control" readonly
                                                            value={{allData?.firstname}}
                                                            placeholder="Enter First Name" />
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Last Name<span class="required"></span></label>
                                                      <input type="text" class="form-control" readonly
                                                            value={{allData?.lastname}} placeholder="Enter Last Name">
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Mobile Number<span class="required"></span></label>
                                                      <input type="text" class="form-control" readonly
                                                            value={{allData?.mobile}} placeholder="Mobile Number">

                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Email<span class="required"></span></label>
                                                      <input type="email" class="form-control" placeholder="Email"
                                                            readonly value={{allData?.email}}>

                                                </div>
                                                
                                                <div class="form-group col-md-4">
                                                      <label>State<span class="required"></span></label>
                                                      <input type="text" class="form-control" placeholder="State"
                                                            readonly value={{allData?.stateData?.name}}>

                                                </div>
                                          </div>
                                    </div>
                              </form>

                              <div class="card-body">
                                    <div class="row">
                                          <div class="form-group col-md-4">
                                                <label for="class_name">Please Select Tour Type <span
                                                            class="required"></span></label>
                                                <select class="form-control"
                                                      (change)="onTourSelect($event.target.value);"
                                                      [disabled]="FinalBooking || FinalBookingcustomized">
                                                      <option value="" selected >Please Select Tour Type</option>
                                                      <option value="normal">Normal</option>
                                                      <option value="customized">Customized Tour</option>

                                                </select>
                                          </div>
                                    </div>
                              </div>

                              <div class="card-body" *ngIf="this.tourtype == 'normal'">
                                    <div class="row">
                                          <div class="form-group col-md-4">
                                                <label for="class_name">Tour Name <span class="required"></span></label>
                                                <select class="form-control" #cat
                                                      (change)="onCategorySelect($event.target.value);"
                                                      [disabled]="FinalBooking" [(ngModel)]="selectedTour">
                                                      <option value="" selected>Plan Name</option>
                                                      <option *ngFor="let item of allTour; let i = index"
                                                            value="{{item._id }}">
                                                            {{ item.title }}</option>
                                                </select>
                                          </div>
                                          <div class="col-md-4" *ngIf="this.FinalBooking">
                                                <div class="form-group">
                                                      <label for="class_name">Select Coupon/Voucher </label>
                                                      <select class="form-control"
                                                            (change)="onCoupanSelect($event.target.value);"
                                                            [(ngModel)]="appliedCoupon">
                                                            <option value="" selected>Select Coupon Code</option>
                                                            <option *ngFor="let item of allCoupan; let i = index"
                                                                  [ngClass]="{
                                                                  'positive' : item.isShow === 'Voucher'
                                                               }" value="{{ item.coupon_code }}">
                                                                  {{ item.coupon_code }}
                                                            </option>
                                                      </select>
                                                </div>
                                          </div>

                                          <div class="form-group col-md-4">
                                                <button type="button" class="btn btn-info confirmbtn" 
                                                      *ngIf="!this.FinalBooking" (click)="handleClick()" [disabled]="isButtonDisabled">
                                                      Confirm
                                                </button>
                                                <!-- <button type="button" class="btn btn-info mt-4" data-toggle="modal"
                                                      data-target="#staticBackdrop" *ngIf="!this.FinalBooking"
                                                      (click)="handleClick()">
                                                      Confirm
                                                </button> -->
                                          </div>
                                          <!-- Modal -->
                                          <div class="modal fade" id="staticBackdrop" data-backdrop="static"
                                                data-keyboard="false" tabindex="-1"
                                                aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                                *ngIf="this.ifTourNotSelected && this.selectedTour != ''">
                                                <div class="modal-dialog modal-lg">
                                                      <div class="modal-content">
                                                            <div class="modal-header">
                                                                  <h5 class="modal-title" id="staticBackdropLabel">Tour
                                                                        Details</h5>
                                                                  <button type="button" class="close"
                                                                        data-dismiss="modal" aria-label="Close"
                                                                        (click)="handleClose()">
                                                                        <span aria-hidden="true">&times;</span>
                                                                  </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                  <ng-container *ngIf="(allOrder | keyvalue)?.length">
                                                                        <div class="row mx-2">
                                                                              <div class="col-12 mb-2">
                                                                                    <h5 class="mb-0 fw-bold">Tour Date
                                                                                    </h5>
                                                                              </div>
                                                                              <div class="col-12">
                                                                                    <div class="inline"
                                                                                          *ngIf="allDates?.start_date.length > 0">
                                                                                          <!-- <label class="select" for="slct"> -->
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                required="required"
                                                                                                #dateSchedule
                                                                                                (change)="onChange($event.target.value)">
                                                                                                <!-- <option
                                                                                                      value="{{selectebatchID}}"
                                                                                                      selected="selected">
                                                                                                      Select option
                                                                                                </option> -->
                                                                                                <option
                                                                                                      value="{{ date._id  }}"
                                                                                                      *ngFor="let date of this.allTourdate?.select_batch; index as i">
                                                                                                      Batch - {{ date.batchNumber }}:
                                                                                                      {{ date.start_date
                                                                                                      | date:
                                                                                                      'dd/MM/yyyy'
                                                                                                      }} - {{
                                                                                                      date.end_date
                                                                                                      | date:
                                                                                                      'dd/MM/yyyy' }}
                                                                                                </option>
                                                                                          </select>

                                                                                    </div>
                                                                              </div>

                                                                              <ng-container
                                                                                    *ngIf="allPackData?.length !== 0">
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>
                                                                                    <div class="col-12">
                                                                                          <h5 class="mb-0 fw-bold">
                                                                                                Package Type</h5>
                                                                                    </div>
                                                                                    <div class="col-6">Type</div>
                                                                                    <div class="col-4">Price (per
                                                                                          person)</div>
                                                                                    <div class="col-2 text-center"><i
                                                                                                class="fa fa-user"
                                                                                                aria-hidden="true"
                                                                                                style="font-size: x-large;"></i>
                                                                                    </div>

                                                                                    <ng-container
                                                                                          *ngFor="let packageData of allPackData">
                                                                                          <div class="col-6">
                                                                                                {{packageData.type !==
                                                                                                'normal' ?
                                                                                                packageData.type+' X' :
                                                                                                ''}}
                                                                                                {{packageData.packType}}
                                                                                          </div>
                                                                                          <div class="col-4">₹
                                                                                                {{packageData.packPrice}}
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(packageData, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{packageData.persons}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(packageData, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>

                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <ng-container *ngIf="totalTraveller <= 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Number Of Adults
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{totalTraveller}}
                                                                                          </div>
                                                                                    </div>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="totalTraveller > 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Number Of Adults
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{totalTraveller}}
                                                                                          </div>
                                                                                    </div>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="this.allTourdate?.childfrom">
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>

                                                                                    <ng-container>
                                                                                          <div class="col-6">
                                                                                                Kids
                                                                                          </div>
                                                                                          <div class="col-4">₹
                                                                                                {{childprice}}
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovechild(childprice, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{numberofchild}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovechild(childprice, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="this.allTourdate?.infantfrom">
                                                                                    <ng-container>
                                                                                          <div class="col-6">
                                                                                                Infant
                                                                                          </div>
                                                                                          <div class="col-4">₹
                                                                                                {{infantprice}}
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovecinfant(infantprice, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{numberofinfant}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovecinfant(infantprice, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>
                                                                              <div class="col-12" *ngIf="this.allTourdate?.childfrom && this.allTourdate?.infantfrom">
                                                                                    <hr>
                                                                              </div>
                                                                              <ng-container *ngIf="this.allTourdate?.childfrom && this.allTourdate?.infantfrom">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Child
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{numberofchild +
                                                                                                numberofinfant }}
                                                                                          </div>
                                                                                    </div>

                                                                              </ng-container>
                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <ng-container *ngIf="totalTraveller <= 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total No. Of traveller
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{totalTraveller +
                                                                                                numberofchild +
                                                                                                numberofinfant}}
                                                                                          </div>
                                                                                    </div>

                                                                              </ng-container>
                                                                              <ng-container *ngIf="totalTraveller > 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total No. Of traveller
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{totalTraveller +
                                                                                                numberofchild +
                                                                                                numberofinfant}}
                                                                                          </div>
                                                                                    </div>

                                                                              </ng-container>

                                                                              <!-- <div class="col-6">Re Himalayan (Solo)</div>
        <div class="col-4">₹ 39,999/-</div>
        <div class="col-2">
            <div class="w-auto d-flex justify-content-center align-items-center">
                <button class="button-minus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">-</button>
                <input type="text" value="1" readonly name="quantity" class="quantity-field border-0 text-center w-25 fw-bold">
                <button class="button-plus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">+</button>
            </div>
        </div>
        <div class="col-6">Re Himalayan (With Pillion)</div>
        <div class="col-4">₹ 31,999/-</div>
        <div class="col-2">
            <div class="w-auto d-flex justify-content-center align-items-center">
                <button class="button-minus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">-</button>
                <input type="text" value="0" readonly name="quantity" class="quantity-field border-0 text-center w-25 fw-bold">
                <button class="button-plus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">+</button>
            </div>
        </div> -->

                                                                              <ng-container
                                                                                    *ngIf="allRoomData?.length !== 0">
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>
                                                                                    <div class="col-12">
                                                                                          <h5 class="mb-0 fw-bold">Room
                                                                                                Sharing</h5>
                                                                                    </div>
                                                                                    <div class="col-6">Type</div>
                                                                                    <div class="col-4">Price (per
                                                                                          person)</div>
                                                                                    <div class="col-2 text-center"><i
                                                                                                class="fa fa-user"
                                                                                                aria-hidden="true"
                                                                                                style="font-size: x-large;"></i>
                                                                                    </div>

                                                                                    <ng-container
                                                                                          *ngFor="let roomData of allRoomData">
                                                                                          <div class="col-6">
                                                                                                {{roomData.roomType}}
                                                                                          </div>
                                                                                          <div class="col-4">₹
                                                                                                {{roomData.roomPrice}}
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(roomData, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{roomData.persons}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(roomData, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>

                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <div class="col-12 mb-2">
                                                                                    <h5 class="mb-0 fw-bold">Boarding
                                                                                          Point</h5>
                                                                              </div>
                                                                              <div class="col-12">
                                                                                    <div class="inline"
                                                                                          *ngIf="allOrder?.boarding_point.length > 0">
                                                                                          <!-- <label class="title-label">BOARDING POINT</label> -->
                                                                                          <!-- <label class="select" for="slct"> -->
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                #boardingPt
                                                                                                (change)="onOptionSelect($event.target.value, 'board');">
                                                                                                <option value=""
                                                                                                      [attr.data-getboardid]=""
                                                                                                      selected="selected">
                                                                                                      Select option
                                                                                                </option>
                                                                                                <option
                                                                                                      value="{{ boardData?.bp }}"
                                                                                                      [attr.data-getboardid]="boardData?.id"
                                                                                                      *ngFor="let boardData of allOrder?.boarding_point_price">
                                                                                                      {{ boardData?.bp
                                                                                                      }} &nbsp;&nbsp;{{
                                                                                                      getSingleBoardPrice(boardData?.bp)
                                                                                                      }}
                                                                                                </option>
                                                                                          </select>

                                                                                    </div>
                                                                              </div>
                                                                              &nbsp;
                                                                              <div class="col-12 mb-2">
                                                                                    <div class="inline"
                                                                                          *ngIf="multipleBoarded?.length !== 0 && multipleBoarded[0]?.bp_type === 'multi' ">
                                                                                          <!-- <label class="title-label">BOARDING TYPE</label> -->
                                                                                          <!-- <label class="select" for="slct"> -->
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                (change)="onOptionSelect($event.target.value, 'boardMulti');"
                                                                                                #boardingMulti>
                                                                                                <ng-container
                                                                                                      *ngFor="let multiboardData of multipleBoarded[0]?.bp_price">

                                                                                                      <option
                                                                                                            value="{{ Object.values(multiboardData)[0] }}"
                                                                                                            *ngIf="Object.values(multiboardData)[0]  != ''">
                                                                                                            {{
                                                                                                            Object.keys(multiboardData)
                                                                                                            }}
                                                                                                            &nbsp;&nbsp;(₹{{
                                                                                                            Object.values(multiboardData)
                                                                                                            }} Per
                                                                                                            Person)
                                                                                                      </option>
                                                                                                </ng-container>
                                                                                          </select>
                                                                                    </div>
                                                                              </div>


                                                                        </div>
                                                                  </ng-container>
                                                            </div>
                                                            <div class="modal-footer justify-content-between">
                                                                  <h3 class="fw-bold">Total: ₹{{finalPrice}}</h3>
                                                                  <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                                                                  <button type="button" class="btn btn-primary"
                                                                        [disabled]="totalTraveller === 0"
                                                                        (click)="setChange()"
                                                                        *ngIf="!this.FinalBooking">Book Tour</button>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>

                                    </div>
                              </div>
                              <div class="card-body" *ngIf="this.tourtype == 'customized'">
                                    <div class="row">
                                          <div class="form-group col-md-4">
                                                <label for="class_name">Tour Name <span class="required"></span></label>
                                                <select class="form-control" #cat
                                                      (change)="onCategorySelect($event.target.value);"
                                                      [disabled]="FinalBookingcustomized" [(ngModel)]="selectedTour">
                                                      <option value="" selected>Plan Name</option>
                                                      <option *ngFor="let item of allTour; let i = index"
                                                            value="{{item._id }}">
                                                            {{ item.title }}</option>
                                                </select>
                                          </div>
                                          <div class="col-md-4" *ngIf="this.FinalBookingcustomized">
                                                <div class="form-group">
                                                      <label for="class_name">Select Coupon/Voucher </label>
                                                      <select class="form-control"
                                                            (change)="onCoupanSelect($event.target.value);"
                                                            [(ngModel)]="appliedCoupon">
                                                            <option value="" selected>Select Coupon Code</option>
                                                            <option *ngFor="let item of allCoupan; let i = index"
                                                                  [ngClass]="{
                                                                  'positive' : item.isShow === 'Voucher'
                                                               }" value="{{ item.coupon_code }}">
                                                                  {{ item.coupon_code }}
                                                            </option>
                                                      </select>
                                                </div>
                                          </div>


                                          <div class="form-group col-md-4">
                                                <button type="button" class="btn btn-info confirmbtn"
                                                      *ngIf="!this.FinalBookingcustomized" (click)="handleClick()"  [disabled]="isButtonDisabled">
                                                      Confirm
                                                </button>
                                                <!-- <button type="button" class="btn btn-info mt-4" data-toggle="modal"
                                                      data-target="#staticBackdrop" *ngIf="!this.FinalBooking"
                                                      (click)="handleClick()">
                                                      Confirm
                                                </button> -->
                                          </div>
                                          <!-- Modal -->
                                          <div class="modal fade" id="staticBackdrop" data-backdrop="static"
                                                data-keyboard="false" tabindex="-1"
                                                aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                                *ngIf="this.ifTourNotSelected && this.selectedTour != ''">
                                                <div class="modal-dialog modal-lg">
                                                      <div class="modal-content">
                                                            <div class="modal-header">
                                                                  <h5 class="modal-title" id="staticBackdropLabel">Tour
                                                                        Details</h5>
                                                                  <button type="button" class="close"
                                                                        data-dismiss="modal" aria-label="Close"
                                                                        (click)="handleClose()">
                                                                        <span aria-hidden="true">&times;</span>
                                                                  </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                  <ng-container *ngIf="(allOrder | keyvalue)?.length">
                                                                        <div class="row mx-2">
                                                                              <div class="col-12 mb-2">
                                                                                    <h5 class="mb-0 fw-bold">Tour Date
                                                                                    </h5>
                                                                              </div>
                                                                              <div class="col-12">
                                                                                    <div class="row">
                                                                                          <div class="col-md-6">
                                                                                                <label for="startDate">Start
                                                                                                      Date:</label>
                                                                                                <input type="date"
                                                                                                      id="startDate"
                                                                                                      class="form-control"
                                                                                                      [(ngModel)]="startDate"
                                                                                                      name="startDate"
                                                                                                      [min]="minStartDate"
                                                                                                      (change)="updateMinEndDate()"
                                                                                                      (change)="updateEndDate()">

                                                                                          </div>
                                                                                          <div class="col-md-6">
                                                                                                <label for="endDate">End
                                                                                                      Date:</label>
                                                                                                <input type="date"
                                                                                                      id="endDate"
                                                                                                      class="form-control"
                                                                                                      [(ngModel)]="endDate"
                                                                                                      name="endDate"
                                                                                                      [min]="minEndDate"
                                                                                                      readonly>

                                                                                          </div>
                                                                                    </div>
                                                                              </div>

                                                                              <ng-container
                                                                                    *ngIf="allPackData?.length !== 0">
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>
                                                                                    <div class="col-12">
                                                                                          <h5 class="mb-0 fw-bold">
                                                                                                Package Type</h5>
                                                                                    </div>
                                                                                    <div class="col-6">Type</div>
                                                                                    <div class="col-4">Price (per
                                                                                          person)</div>
                                                                                    <div class="col-2 text-center"><i
                                                                                                class="fa fa-user"
                                                                                                aria-hidden="true"
                                                                                                style="font-size: x-large;"></i>
                                                                                    </div>

                                                                                    <ng-container
                                                                                          *ngFor="let packageData of allPackData let i = index">
                                                                                          <div class="col-6">
                                                                                                {{packageData.type !==
                                                                                                'normal' ?
                                                                                                packageData.type+' X' :
                                                                                                ''}}
                                                                                                {{packageData.packType}}
                                                                                          </div>
                                                                                          <div class="col-4">
                                                                                                <input type="text"
                                                                                                      class="form-control mb-2"
                                                                                                      placeholder="Price"
                                                                                                      aria-label="Infant"
                                                                                                      (keypress)="onlyNumberKey($event)"
                                                                                                      [(ngModel)]="packageValues[i]"
                                                                                                      (ngModelChange)="onPriceChange(i, $event)">
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersonsPackage(i, packageData,'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            [(ngModel)]="numbercustomizedpackage[i]"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersonsPackage(i,packageData, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>

                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <ng-container
                                                                                    *ngIf="getTotalSelectedPackages() <= 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Number Of Adults
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{
                                                                                                getTotalSelectedPackages()
                                                                                                }}
                                                                                          </div>
                                                                                    </div>
                                                                              </ng-container>
                                                                              <ng-container
                                                                                    *ngIf="getTotalSelectedPackages() > 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Number Of Adults
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{getTotalSelectedPackages()}}
                                                                                          </div>
                                                                                    </div>
                                                                              </ng-container>
                                                                              <ng-container>
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>

                                                                                    <ng-container>
                                                                                          <div class="col-6">
                                                                                                Kids
                                                                                          </div>
                                                                                          <div class="col-4">
                                                                                                <input type="text"
                                                                                                      class="form-control mb-2"
                                                                                                      placeholder="Kids"
                                                                                                      aria-label="Kids"
                                                                                                      (keypress)="onlyNumberKey($event)"
                                                                                                      [(ngModel)]="kidsValue"
                                                                                                      (ngModelChange)="onPriceChangeChild( $event)">
                                                                                                      
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovechild(kidsValue, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{numberofchild}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold" >
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovechild(kidsValue, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>
                                                                              <ng-container>


                                                                                    <ng-container>
                                                                                          <div class="col-6">
                                                                                                Infant
                                                                                          </div>
                                                                                          <div class="col-4">
                                                                                                <input type="text"
                                                                                                      class="form-control"
                                                                                                      placeholder="Infant"
                                                                                                      aria-label="Infant"
                                                                                                      (keypress)="onlyNumberKey($event)"
                                                                                                      [(ngModel)]="infantValue"
                                                                                                      (ngModelChange)="onPriceChangeInfant( $event)">
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovecinfant(infantValue, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{numberofinfant}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addremovecinfant(infantValue, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>
                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <ng-container>
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Child
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{numberofchild +
                                                                                                numberofinfant }}
                                                                                          </div>
                                                                                    </div>

                                                                              </ng-container>
                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <ng-container
                                                                                    *ngIf="getTotalSelectedPackages() <= 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total No. Of traveller
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{getTotalSelectedPackages()
                                                                                                +
                                                                                                numberofchild +
                                                                                                numberofinfant}}
                                                                                          </div>
                                                                                    </div>

                                                                              </ng-container>
                                                                              <ng-container
                                                                                    *ngIf="getTotalSelectedPackages() > 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total No. Of traveller
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{getTotalSelectedPackages()
                                                                                                +
                                                                                                numberofchild +
                                                                                                numberofinfant}}
                                                                                          </div>
                                                                                    </div>

                                                                              </ng-container>

                                                                              <ng-container
                                                                                    *ngIf="allRoomData?.length !== 0">
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>
                                                                                    <div class="col-12">
                                                                                          <h5 class="mb-0 fw-bold">Room
                                                                                                Sharing</h5>
                                                                                    </div>
                                                                                    <div class="col-6">Type</div>
                                                                                    <div class="col-4">Price (per
                                                                                          person)</div>
                                                                                    <div class="col-2 text-center"><i
                                                                                                class="fa fa-user"
                                                                                                aria-hidden="true"
                                                                                                style="font-size: x-large;"></i>
                                                                                    </div>

                                                                                    <ng-container
                                                                                          *ngFor="let roomData of allRoomData let i = index">
                                                                                          <div class="col-6">
                                                                                                {{roomData.roomType}}
                                                                                          </div>
                                                                                          <div class="col-4">
                                                                                                <input type="text"
                                                                                                      class="form-control mb-2"
                                                                                                      placeholder="Price"
                                                                                                      aria-label="Infant"
                                                                                                      (keypress)="onlyNumberKey($event)"
                                                                                                      [(ngModel)]="roomValues[i]"
                                                                                                      (ngModelChange)="onPriceChangeRoomSharing(i, $event)">
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersonsRoom(i, roomData,'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            [(ngModel)]="numbercustomizedroom[i]"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersonsRoom(i,roomData, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>

                                                                                          </div>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                          *ngIf="getTotalSelectedRoom() ">
                                                                                          <div class="col-12">
                                                                                                <hr>
                                                                                          </div>
                                                                                          <div
                                                                                                class="col-12 d-flex align-items-center justify-content-between">
                                                                                                <div>
                                                                                                      Total Number Of
                                                                                                      Adults
                                                                                                </div>
                                                                                                <div
                                                                                                      style="margin-right: 7%;">
                                                                                                      {{getTotalSelectedRoom()}}
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>

                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <div class="col-12 mb-2">
                                                                                    <h5 class="mb-0 fw-bold">Boarding
                                                                                          Point</h5>
                                                                              </div>
                                                                              <div class="col-12">
                                                                                    <div class="inline"
                                                                                          *ngIf="allOrder?.boarding_point.length > 0">
                                                                                          <!-- <label class="title-label">BOARDING POINT</label> -->
                                                                                          <!-- <label class="select" for="slct"> -->
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                style="margin-bottom: 10px"
                                                                                                #boardingPt
                                                                                                (change)="onOptionSelect($event.target.value, 'board');">
                                                                                                <option value=""
                                                                                                      [attr.data-getboardid]=""
                                                                                                      selected="selected">
                                                                                                      Select option
                                                                                                </option>
                                                                                                <option
                                                                                                      value="{{ boardData?.bp }}"
                                                                                                      [attr.data-getboardid]="boardData?.id"
                                                                                                      *ngFor="let boardData of allOrder?.boarding_point_price">
                                                                                                      {{ boardData?.bp
                                                                                                      }}
                                                                                                </option>
                                                                                          </select>
                                                                                          <div class="row"
                                                                                                *ngIf="multipleBoarded?.length !== 0 && multipleBoarded[0]?.bp_type === 'single'">
                                                                                                <div class="col-md-4">
                                                                                                      <h6 class="mb-1">
                                                                                                            Single
                                                                                                            Boarding
                                                                                                            Price</h6>
                                                                                                      <input type="text"
                                                                                                            class="form-control mb-4"
                                                                                                            placeholder="Price"
                                                                                                            aria-label="singalboardingprice"
                                                                                                            [(ngModel)]="singalboardingprice"
                                                                                                            (input)="updatePriceEntered('singal', singalboardingprice)">
                                                                                                </div>
                                                                                          </div>
                                                                                          <div class="row"
                                                                                          *ngIf="multipleBoarded?.length !== 0 && multipleBoarded[0]?.bp_type === 'multi'">
                                                                                          <div class="col-md-2">
                                                                                                <h6 class="mb-1">
                                                                                                      Sleeper</h6>
                                                                                                <input type="text"
                                                                                                      class="form-control mb-4"
                                                                                                      placeholder="Price"
                                                                                                      aria-label="sleeperPrice"
                                                                                                      [(ngModel)]="sleeperPrice"
                                                                                                      (input)="updatePriceEntered('sleeper', sleeperPrice)">

                                                                                          </div>
                                                                                          <div class="col-md-2">
                                                                                                <h6 class="mb-1">
                                                                                                      3AC</h6>
                                                                                                <input type="text"
                                                                                                      class="form-control mb-4"
                                                                                                      placeholder="Price"
                                                                                                      aria-label="ac3Price"
                                                                                                      [(ngModel)]="ac3Price"
                                                                                                      (input)="updatePriceEntered('ac3', ac3Price)">
                                                                                          </div>
                                                                                          <div class="col-md-2">
                                                                                                <h6 class="mb-1">
                                                                                                      2AC</h6>
                                                                                                <input type="text"
                                                                                                      class="form-control mb-4"
                                                                                                      placeholder="Price"
                                                                                                      aria-label="ac2Price"
                                                                                                      [(ngModel)]="ac2Price"
                                                                                                      (input)="updatePriceEntered('ac2', ac2Price)">
                                                                                          </div>
                                                                                          <div class="col-md-2">
                                                                                                <h6 class="mb-1">
                                                                                                      1AC</h6>
                                                                                                <input type="text"
                                                                                                      class="form-control mb-4"
                                                                                                      placeholder="Price"
                                                                                                      aria-label="ac1Price"
                                                                                                      [(ngModel)]="ac1Price"
                                                                                                      (input)="updatePriceEntered('ac1', ac1Price)">
                                                                                          </div>
                                                                                          <div class="col-md-2">
                                                                                                <h6 class="mb-1">
                                                                                                      Flight</h6>
                                                                                                <input type="text"
                                                                                                      class="form-control mb-4"
                                                                                                      placeholder="Price"
                                                                                                      aria-label="flightPrice"
                                                                                                      [(ngModel)]="flightPrice"
                                                                                                      (input)="updatePriceEntered('flight', flightPrice)">
                                                                                          </div>

                                                                                    </div>
                                                                                          <div class="row">
                                                                                                <div class="col-md-4">
                                                                                                      <h6 class="mb-1">
                                                                                                            Booking
                                                                                                            Price </h6>
                                                                                                      <input type="number"
                                                                                                            class="form-control mb-4"
                                                                                                            placeholder="Price"
                                                                                                            aria-label="bookingprice"
                                                                                                            [(ngModel)]="bookingpricecust"
                                                                                                            >

                                                                                                </div>
                                                                                                <!-- <div class="col-md-1">
                                                                                                </div> -->

                                                                                                <div class="col-md-4 mt-3">
                                                                                                      <h6 class="mb-1">
                                                                                                            GST </h6>
                                                                                                      <div class="ml-4">
                                                                                                            <input class="form-check-input"
                                                                                                                  type="radio"
                                                                                                                  name="gst"
                                                                                                                  id="flexRadioDefault1"
                                                                                                                  value="inclusive"
                                                                                                                  [(ngModel)]="gst"
                                                                                                                  checked=[true]>
                                                                                                            <label
                                                                                                                  class="form-check-label">
                                                                                                                  Inclusive
                                                                                                            </label>
                                                                                                            <input class="form-check-input  ml-4"
                                                                                                                  type="radio"
                                                                                                                  name="gst"
                                                                                                                  id="flexRadioDefault1"
                                                                                                                  value="exclusive"
                                                                                                                  [(ngModel)]="gst">
                                                                                                            <label
                                                                                                                  class="form-check-label ml-5">
                                                                                                                  Exclusive
                                                                                                            </label>
                                                                                                      </div>
                                                                                                </div>
                                                                                                <div *ngIf="this.allTourdate.domesticorInternational == 'international'" class="col-md-3 mt-3"  >
                                                                                                      <h6 class="mb-1">
                                                                                                            TCS </h6>
                                                                                                      <div class="ml-4">
                                                                                                            <input class="form-check-input"
                                                                                                                  type="radio"
                                                                                                                  name="tcs"
                                                                                                                  id="flexRadioDefault2"
                                                                                                                  value="inclusive"
                                                                                                                  [(ngModel)]="tcs"
                                                                                                                  checked=[true]>
                                                                                                            <label
                                                                                                                  class="form-check-label">
                                                                                                                  Inclusive
                                                                                                            </label>
                                                                                                            <input class="form-check-input  ml-2"
                                                                                                                  type="radio"
                                                                                                                  name="tcs"
                                                                                                                  id="flexRadioDefault2"
                                                                                                                  value="exclusive"
                                                                                                                  [(ngModel)]="tcs">
                                                                                                            <label
                                                                                                                  class="form-check-label ml-4">
                                                                                                                  Exclusive
                                                                                                            </label>
                                                                                                      </div>
                                                                                                </div>

                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              &nbsp;
                                                                              <!-- <div class="col-12 mb-2">
                                                                                    <div class="inline"
                                                                                          *ngIf="multipleBoarded?.length !== 0 && multipleBoarded[0]?.bp_type === 'multi' ">
                                    
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                (change)="onOptionSelect($event.target.value, 'boardMulti');"
                                                                                                #boardingMulti>
                                                                                                <ng-container
                                                                                                      *ngFor="let multiboardData of multipleBoarded[0]?.bp_price">

                                                                                                      <option
                                                                                                            value="{{ Object.values(multiboardData)[0] }}"
                                                                                                            *ngIf="Object.values(multiboardData)[0]  != ''">
                                                                                                            {{
                                                                                                            Object.keys(multiboardData)
                                                                                                            }}
                                                                                                            &nbsp;&nbsp;(₹{{
                                                                                                            Object.values(multiboardData)
                                                                                                            }} Per
                                                                                                            Person)
                                                                                                      </option>
                                                                                                </ng-container>
                                                                                          </select>
                                                                                        
                                                                                    </div>
                                                                              </div> -->


                                                                        </div>
                                                                  </ng-container>
                                                            </div>
                                                            <div class="modal-footer justify-content-between">
                                                                  <h3 class="fw-bold">Total: ₹{{finalPrice}}</h3>
                                                                  <!-- [disabled]="totalTraveller === 0" <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                                                                  <button type="button" class="btn btn-primary"
                                                                        [disabled]="totalTravellercustomized === 0"
                                                                        (click)="setChangecustomized()">Book
                                                                        Tour</button>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>

                                    </div>
                              </div>
                              <div class="card-body" *ngIf="this.FinalBooking || FinalBookingcustomized">
                                    <div class="row">
                                          <div class="col-md-4">
                                                <div class="card py-3"
                                                      *ngIf="(allCheckoutTourData | keyvalue)?.length || (allTourData | keyvalue)?.length">
                                                      <ng-container
                                                            *ngIf="allCheckoutTourData.travel_packageType_data?.length !== 0">
                                                            <h6 class="d-flex align-items-center mx-3 mb-0 fw-bold"
                                                                  style="font-weight: 800;">Package Type :</h6>
                                                            <span class="d-flex align-items-center mx-3"
                                                                  *ngFor="let pack of allCheckoutTourData.travel_packageType_data">
                                                                  <ng-container *ngIf="pack.persons !== 0 ">
                                                                        <h6 class="text-muted fw-bolder mb-0">
                                                                              {{pack.type === 'normal' ? '' :
                                                                              pack.type+' X '}}{{pack.packType}}
                                                                        </h6>
                                                                        <hr>
                                                                        <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹
                                                                              {{pack.packPrice?.toLocaleString('en-IN')
                                                                              }} x
                                                                              {{pack.persons}} = ₹ {{(pack.packPrice *
                                                                              pack.persons)?.toLocaleString('en-IN')}}
                                                                        </h5>
                                                                  </ng-container>
                                                            </span>
                                                      </ng-container>

                                                      <hr />
                                                      <ng-container
                                                            *ngIf="allCheckoutTourData.travel_roomSharing_data?.length !== 0">
                                                            <h6 class="d-flex align-items-center mx-3 mb-0 fw-bold"
                                                                  style="font-weight: 800;">Room Sharing :</h6>
                                                            <span class="d-flex align-items-center mx-3"
                                                                  *ngFor="let room of allCheckoutTourData.travel_roomSharing_data">
                                                                  <ng-container *ngIf="room.persons !== 0">
                                                                        <h6 class="text-muted fw-bolder mb-0">
                                                                              {{room.roomType}}</h6>
                                                                        <hr>
                                                                        <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹
                                                                              {{room.roomPrice?.toLocaleString('en-IN')
                                                                              }} x {{room.persons}} = ₹
                                                                              {{(room.roomPrice *
                                                                              room.persons)?.toLocaleString('en-IN')}}
                                                                        </h5>
                                                                  </ng-container>
                                                            </span>
                                                      </ng-container>
                                                      <hr>
                                                      <ng-container
                                                            *ngIf="allCheckoutTourData?.child_price || allCheckoutTourData?.infant_price">
                                                            <h6 class="d-flex align-items-center mx-3 mb-0 fw-bold"
                                                                  style="font-weight: 800;">Child/Infant :</h6>
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <ng-container
                                                                        *ngIf="allCheckoutTourData?.child_price">
                                                                        <h6 class="text-muted fw-bolder mb-0">
                                                                              Child</h6>
                                                                        <hr>
                                                                        <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹
                                                                              {{
                                                                              singlachildprice?.toLocaleString('en-IN')
                                                                              }} x {{allCheckoutTourData?.child_count}}
                                                                              = ₹
                                                                              {{allCheckoutTourData?.child_price
                                                                              }}
                                                                        </h5>

                                                                  </ng-container>

                                                            </span>
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <ng-container
                                                                        *ngIf="allCheckoutTourData?.infant_price">
                                                                        <h6 class="text-muted fw-bolder mb-0">
                                                                              Infant</h6>
                                                                        <hr>
                                                                        <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹
                                                                              {{
                                                                              singlainfentprice?.toLocaleString('en-IN')
                                                                              }} x {{allCheckoutTourData?.infant_count}}
                                                                              = ₹
                                                                              {{allCheckoutTourData?.infant_price
                                                                              }}
                                                                        </h5>

                                                                  </ng-container>

                                                            </span>
                                                      </ng-container>
                                                      <hr>
                                                      <h6 class="d-flex align-items-center mx-3 mb-0 fw-bold"
                                                            style="font-weight: 800;">Boarding Point :</h6>
                                                      <span *ngIf="this.FinalBooking"
                                                            class="d-flex align-items-center mx-3">

                                                            <h6 class="text-muted fw-bolder mb-0">
                                                                  <span style="font-weight: bold;">
                                                                    {{ allCheckoutTourData?.travel_boarding_point?.boarding }}
                                                                  </span>
                                                                  &nbsp;<br>
                                                                  <small *ngIf="allCheckoutTourData?.boardingType" class="fw-bolder" >
                                                                    <b class="black">Boarding Type :</b>
                                                                  </small><br>
                                                                  {{ allCheckoutTourData?.boardingType ? "(" + allCheckoutTourData?.boardingType + ")" : "" }}
                                                                </h6>
                                                            <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹ {{
                                                                  allCheckoutTourData?.travel_boardingPrice?.toLocaleString('en-IN')
                                                                  }} x {{allCheckoutTourData?.travel_no_of_traveler}} =
                                                                  ₹ {{ (allCheckoutTourData?.travel_no_of_traveler *
                                                                  allCheckoutTourData?.travel_boardingPrice)?.toLocaleString('en-IN')}}
                                                            </h5>

                                                      </span>
                                                      <span *ngIf="this.FinalBookingcustomized"
                                                            class="d-flex align-items-center mx-3">

                                                            <h6 class="text-muted fw-bolder mb-0">{{
                                                                  allCheckoutTourData?.boardingpointustomized
                                                                  }} &nbsp;<br>
                                                                  <small *ngIf="allCheckoutTourData?.boardingType"
                                                                        class="fw-bolder"><b class="black">Boarding Type
                                                                              :</b></small><br>
                                                                  {{ allCheckoutTourData?.boardingType ? "(" +
                                                                  allCheckoutTourData?.boardingType + ")" : "" }}
                                                            </h6>
                                                            <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹ {{
                                                                  allCheckoutTourData?.boardingTypepricecustomized?.toLocaleString('en-IN')
                                                                  }} x {{allCheckoutTourData?.travel_no_of_traveler}} =
                                                                  ₹ {{ (allCheckoutTourData?.travel_no_of_traveler *
                                                                  allCheckoutTourData?.boardingTypepricecustomized)?.toLocaleString('en-IN')}}
                                                            </h5>
                                                      </span>
                                                      <hr>
                                                      <div *ngIf="this.FinalBooking || this.FinalBookingcustomized">
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <h6 class="text-muted fw-bolder mb-0">Total Member
                                                                  </h6>
                                                                  <h5 class="ml-auto fw-bolder mb-0 fontmamber">{{
                                                                        allCheckoutTourData?.travel_no_of_traveler }}
                                                                  </h5>
                                                            </span>
                                                      </div>


                                                      <div *ngIf="isCouponApplied">
                                                            <hr>
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <h6 class="text-muted fw-bolder mb-0">Success Coupon
                                                                        applied
                                                                  </h6>
                                                                  <!-- <h5 class="ml-auto fw-bolder mb-0 font">{{
                                                                        finalPrice?.toLocaleString('en-IN')
                                                                        }}<small class="text-muted ms-auto"
                                                                              *ngIf="discountedPriceByCoupon !== 0"
                                                                              style="font-size: small;"> - ₹{{
                                                                              discountedPriceByCoupon?.toLocaleString('en-IN')
                                                                            }}
                                                                            <span *ngIf="couponRes.coupon_type == 'Voucher'; else notVoucher">(By voucher)</span>
                                                                            <ng-template #notVoucher>
                                                                                  <span>(By coupon)</span>
                                                                            </ng-template>
                                                                            </small></h5>
                                                                  <small *ngIf="couponmessage">Invalid
                                                                        Coupon</small> -->
                                                                  <h5 class="ml-auto fw-bolder mb-0 fontmamber"
                                                                        *ngIf="discountedPriceByCoupon !== 0">- ₹{{
                                                                        discountedPriceByCoupon?.toLocaleString('en-IN')
                                                                        }}
                                                                        <span
                                                                              *ngIf="couponRes.coupon_type == 'Voucher'; else notVoucher">(By
                                                                              voucher)</span>
                                                                        <ng-template #notVoucher>
                                                                              <span>(By coupon)</span>
                                                                        </ng-template>
                                                                  </h5>
                                                                  <small *ngIf="couponmessage">Invalid
                                                                        Coupon</small>

                                                            </span>
                                                      </div>
                                                      <div *ngIf="this.FinalBooking || this.FinalBookingcustomized">
                                                            <hr />
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <h6 class="text-muted fw-bolder mb-0"> Total Amount
                                                                  </h6>
                                                                  <h4 class="ml-auto fw-bolder mb-0">{{
                                                                        allCheckoutTourData?.TourPrice?.toLocaleString('en-IN', {
                                                                        minimumFractionDigits: 0, maximumFractionDigits:
                                                                        0 })
                                                                        }}</h4>
                                                            </span>
                                                      </div>
                                                      <div *ngIf="this.FinalBooking || this.FinalBookingcustomized">
                                                            <hr />
                                                            <span  *ngIf="this.allCheckoutTourData?.domesticorInternational == 'international'" class="d-flex align-items-center mx-3">
                                                                  <h4 class="fw-bolder mb-0" style="font-size: 17px; color: black;" >This Tour are GST {{this.allTourData?.gst}} and TCS are {{
                                                                        this.allTourData?.tcs
                                                                         }}
                                                                  </h4>
                                                            </span>
                                                            <span  *ngIf="this.allCheckoutTourData?.domesticorInternational == 'domestic'" class="d-flex align-items-center mx-3">
                                                                  <h4 class="fw-bolder mb-0" style="font-size: 17px; color: black;" >This Tour are GST {{this.allTourData?.gst}} 
                                                                  </h4>
                                                            </span>
                                                      </div>
                                                </div>
                                                <div class="form-group"
                                                      *ngIf="this.FinalBooking || this.FinalBookingcustomized">
                                                      <label for="class_name">Payment
                                                            Type<span class="required"></span></label>
                                                      <select class="form-control pay_type"
                                                            [(ngModel)]="payment_status">
                                                            <option value="" selected>Payment Type</option>
                                                            <option value="Complete">Completed</option>
                                                            <option value="Partial">Partial</option>
                                                      </select>
                                                </div>
                                                <div class="form-group"
                                                      *ngIf="this.allCheckoutTourData?.domesticorInternational == 'international'">
                                                      <label for="class_name">Pan Card
                                                            <span class="required"></span></label>
                                                      <input type="text" class="form-control" [(ngModel)]="pan_card"
                                                            placeholder="Enter Pan Card Number" />

                                                </div>
                                                <div class="form-group"
                                                      *ngIf="this.FinalBooking || this.FinalBookingcustomized">
                                                      <label>Note</label>

                                                      <textarea [(ngModel)]="bookingNote" class="form-control" rows="4"
                                                            cols="50"></textarea>
                                                </div>
                                          </div>
                                          <!-- <div class="col-md-2">
                                                <button type="submit" class="btn btn-info" (click)="setChange()"
                                                      style="margin-top: 12px;"
                                                      *ngIf="!this.FinalBooking">Confirm</button>
                                          </div> -->
                                          <div class="col-md-5"
                                                *ngIf="this.FinalBooking || this.FinalBookingcustomized">
                                                <div *ngIf="this.allCheckoutTourData?.domesticorInternational == 'domestic'" >
                                                      <div *ngIf="allTourData?.gst == 'inclusive'" class="card">
                                                            <div *ngIf="(allOtherCharges | keyvalue)?.length" class="mt-3">
                                                                  <div>
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                              </h6>
                                                                              <h5 class="ml-auto fw-bolder mb-0">₹{{(this.FinalTourPrice)?.toLocaleString('en-IN')
                                                                                    }}
                                                                              </h5>
                                                                        </span>
                                                                  </div>
                                                                  <hr />
                                                                  <div
                                                                        *ngIf="allData?.stateData?.iso2 === 'MP'; else outerUser">
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">SGST ({{
                                                                                    allOtherCharges?.sgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithSGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
      
                                                                        <hr />
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">CGST ({{
                                                                                    allOtherCharges?.cgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithCGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                      
                                                                  </div>
                                                                  <ng-template #outerUser>
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">IGST ({{
                                                                                    allOtherCharges?.igst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithIGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <br>
                                                                  </ng-template>
                                                                  <hr />
      
                                                            </div>
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <small class="text-muted mb-0"
                                                                        style="font-size: 25px;">Total
                                                                        Amount </small>
                                                                  <small class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        totalAmountToPaid?.toLocaleString('en-IN')
                                                                        }}</small>
                                                            </span>
      
                                                            <div *ngIf="this.tourtype == 'normal'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}/per-person
                                                                  </label>
                                                            </div>
                                                            <div *ngIf="this.tourtype == 'customized'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}
                                                                  </label>
                                                            </div>

      
                                                      </div>
                                                      <div *ngIf="this.allTourData?.gst == 'exclusive'" class="card">
                                                            <div *ngIf="(allOtherCharges | keyvalue)?.length" class="mt-3">
      
                                                                  <div >
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                              </h6>
                                                                              <h5 class="ml-auto fw-bolder mb-0">
                                                                                    <!-- ₹{{
                                                                                    finalPrice?.toLocaleString('en-IN')
                                                                                    }} -->
                                                                                    ₹{{this.FinalTourPrice?.toLocaleString('en-IN')}}
                                                                                          
                                                                              </h5>
                                                                        </span>
                                                                       
                                                                  </div>
                                                                  <!-- <ng-template #outer>
                                                                  
                                                                        <span
                                                                              class="d-flex align-items-center mx-3">
                                                                              <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                              </h6>
                                                                              <h6 class="ml-auto fw-bolder mb-0">₹{{this.FinalTourPrice?.toLocaleString('en-IN')
                                                                              }}
                                                                                  
                                                                              </h6>
                                                                        </span>
                                                                  </ng-template> -->
                                                                  <hr />
                                                                  <div
                                                                        *ngIf="allData?.stateData?.iso2 == 'MP'; else outerUser">
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">SGST ({{
                                                                                    allOtherCharges?.sgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithCGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
      
                                                                        <hr />
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">CGST ({{
                                                                                    allOtherCharges?.cgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithCGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        
                                                                  </div>
                                                                  <ng-template #outerUser>
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">IGST ({{
                                                                                    allOtherCharges?.igst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithIGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <br>
                                                                  </ng-template>
                                                                  <hr />
      
                                                            </div>
                                                            <!-- <span *ngIf="this.allCheckoutTourData?.domesticorInternational == 'international'"
                                                                  class="d-flex align-items-center mx-3">
                                                                  <small class="text-muted mb-0"
                                                                        style="font-size: 25px;">Total
                                                                        Amount </small>
      
                                                                  <small *ngIf="this.allCheckoutTourData?.user_id?.state !== 'MP' || '' "
                                                                        class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        (totalAmountToPaid)?.toLocaleString('en-IN' , {
                                                                        minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }}
                                                                  </small>
                                                                  <small *ngIf="this.allCheckoutTourData?.user_id?.state == 'MP'"
                                                                        class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        (totalAmountToPaid)?.toLocaleString('en-IN', {
                                                                        minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }}
                                                                  </small>
      
                                                            </span> -->
                                                            <span 
                                                                  class="d-flex align-items-center mx-3">
                                                                  <small class="text-muted mb-0"
                                                                        style="font-size: 25px;">Total Amount
                                                                         </small>
                                                                  <small 
                                                                        class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        (totalAmountToPaid)?.toLocaleString('en-IN' , {
                                                                        minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }}
                                                                  </small>
                                                            </span>
      
                                                            <div *ngIf="this.tourtype == 'normal'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}/per-person
                                                                  </label>
                                                            </div>
                                                            <div *ngIf="this.tourtype == 'customized'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}
                                                                  </label>
                                                            </div>
      
                                                      </div>
                                                </div>

                                                <div *ngIf="this.allCheckoutTourData?.domesticorInternational == 'international'">
                                                      <div *ngIf="this.allTourData?.gst == 'exclusive' && this.allTourData?.tcs == 'inclusive'  " class="card">
                                                            <div *ngIf="(allOtherCharges | keyvalue)?.length" class="mt-3">
      
                                                                  <div>
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                              </h6>
                                                                              <h5 class="ml-auto fw-bolder mb-0">
                                                                                    <!-- ₹{{
                                                                                    finalPrice?.toLocaleString('en-IN')
                                                                                    }} -->
                                                                                    ₹{{this.FinalTourPrice?.toLocaleString('en-IN')}}
                                                                                          
                                                                              </h5>
                                                                        </span>
                                                
                                                                  </div>
                                                                
                                                                  <hr />
                                                                  <div
                                                                        *ngIf="allData?.stateData?.iso2 == 'MP'; else outerUser">
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">SGST ({{
                                                                                    allOtherCharges?.sgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithSGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
      
                                                                        <hr />
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">CGST ({{
                                                                                    allOtherCharges?.cgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithCGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <hr />
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithTCS?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </div>
                                                                  <ng-template #outerUser>
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">IGST ({{
                                                                                    allOtherCharges?.igst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithIGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithTCS?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </ng-template>
                                                                  <hr />
                                                            </div>
                                                           
                                                            <span 
                                                                  class="d-flex align-items-center mx-3">
                                                                  <small class="text-muted mb-0"
                                                                        style="font-size: 25px;">Total
                                                                        Amount </small>
                                                                  <small 
                                                                        class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        (totalAmountToPaid)?.toLocaleString('en-IN', {
                                                                        minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }}
                                                                  </small>
      
                                                            </span>
                  
                                                            <div *ngIf="this.tourtype == 'normal'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}/per-person
                                                                  </label>
                                                            </div>
                                                            <div *ngIf="this.tourtype == 'customized'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}
                                                                  </label>
                                                            </div>
                                                      </div>
                                                      <div *ngIf="this.allTourData?.gst == 'inclusive' && this.allTourData?.tcs == 'exclusive'  " class="card">
                                                            <div *ngIf="(allOtherCharges | keyvalue)?.length" class="mt-3">
      
                                                                  <div>
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                              </h6>
                                                                              <h5 class="ml-auto fw-bolder mb-0">
                                                                                    <!-- ₹{{
                                                                                    finalPrice?.toLocaleString('en-IN')
                                                                                    }} -->
                                                                                    ₹{{this.FinalTourPrice?.toLocaleString('en-IN')}}
                                                                                          
                                                                              </h5>
                                                                        </span>
                                                
                                                                  </div>
                                                                
                                                                  <hr />
                                                                  <div
                                                                        *ngIf="allData?.stateData?.iso2 == 'MP'; else outerUser">
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">SGST ({{
                                                                                    allOtherCharges?.sgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithSGST?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
      
                                                                        <hr />
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">CGST ({{
                                                                                    allOtherCharges?.cgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithCGST?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <hr />
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithTCSNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </div>
                                                                  <ng-template #outerUser>
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">IGST ({{
                                                                                    allOtherCharges?.igst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithIGST?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithTCSNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </ng-template>
                                                                  <hr />
                                                            </div>
                                                           
                                                            <span 
                                                                  class="d-flex align-items-center mx-3">
                                                                  <small class="text-muted mb-0"
                                                                        style="font-size: 25px;">Total
                                                                        Amount </small>
                                                                  <small 
                                                                        class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        (totalAmountToPaid)?.toLocaleString('en-IN', {
                                                                        minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }}
                                                                  </small>
      
                                                            </span>
                                                            <div *ngIf="this.tourtype == 'normal'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}/per-person
                                                                  </label>
                                                            </div>
                                                            <div *ngIf="this.tourtype == 'customized'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}
                                                                  </label>
                                                            </div>
                                                      </div>
                                                      <div *ngIf="this.allTourData?.gst == 'exclusive' && this.allTourData?.tcs == 'exclusive'  " class="card">
                                                            <div *ngIf="(allOtherCharges | keyvalue)?.length" class="mt-3">
      
                                                                  <div>
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                              </h6>
                                                                              <h5 class="ml-auto fw-bolder mb-0">
                                                                                    <!-- ₹{{
                                                                                    finalPrice?.toLocaleString('en-IN')
                                                                                    }} -->
                                                                                    ₹{{this.FinalTourPrice?.toLocaleString('en-IN')}}
                                                                                          
                                                                              </h5>
                                                                        </span>
                                                
                                                                  </div>
                                                                
                                                                  <hr />
                                                                  <div
                                                                        *ngIf="allData?.stateData?.iso2 == 'MP'; else outerUser">
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">SGST ({{
                                                                                    allOtherCharges?.sgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithSGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
      
                                                                        <hr />
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">CGST ({{
                                                                                    allOtherCharges?.cgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithCGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <hr />
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithTCSNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </div>
                                                                  <ng-template #outerUser>
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">IGST ({{
                                                                                    allOtherCharges?.igst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    this.amtWithIGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    this.amtWithTCSNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </ng-template>
                                                                  <hr />
                                                            </div>
                                                            <span 
                                                                  class="d-flex align-items-center mx-3">
                                                                  <small class="text-muted mb-0"
                                                                        style="font-size: 25px;">Total
                                                                        Amount </small>
                                                                  <small 
                                                                        class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        (totalAmountToPaid)?.toLocaleString('en-IN', {
                                                                        minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }}
                                                                  </small>
      
                                                            </span>
                                                            <div *ngIf="this.tourtype == 'normal'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}/per-person
                                                                  </label>
                                                            </div>
                                                            <div *ngIf="this.tourtype == 'customized'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}
                                                                  </label>
                                                            </div>
                                                      </div>
                                                      <div *ngIf="this.allTourData?.gst == 'inclusive' && this.allTourData?.tcs == 'inclusive'  " class="card">
                                                            <div *ngIf="(allOtherCharges | keyvalue)?.length" class="mt-3">
      
                                                                  <div>
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                              </h6>
                                                                              <h5 class="ml-auto fw-bolder mb-0">
                                                                                    <!-- ₹{{
                                                                                    finalPrice?.toLocaleString('en-IN')
                                                                                    }} -->
                                                                                    ₹{{this.FinalTourPrice?.toLocaleString('en-IN')}}
                                                                                          
                                                                              </h5>
                                                                        </span>
                                                
                                                                  </div>
                                                                
                                                                  <hr />
                                                                  <div
                                                                        *ngIf="allData?.stateData?.iso2 == 'MP'; else outerUser">
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">SGST ({{
                                                                                    allOtherCharges?.sgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithSGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
      
                                                                        <hr />
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">CGST ({{
                                                                                    allOtherCharges?.cgst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithCGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <hr />
                                                                        <span 
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    amtWithTCSNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </div>
                                                                  <ng-template #outerUser>
                                                                        <span class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">IGST ({{
                                                                                    allOtherCharges?.igst }}%)({{this.allTourData?.gst}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    this.amtWithIGSTNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                              class="d-flex align-items-center mx-3">
                                                                              <small class="text-muted mb-0">TCS ({{
                                                                                    allOtherCharges?.tcs }}%)({{this.allTourData?.tcs}})</small>
                                                                              <small class="ml-auto fw-bolder mb-0">{{
                                                                                    this.amtWithTCSNew?.toLocaleString('en-IN')
                                                                                    }}</small>
                                                                        </span>
                                                                  </ng-template>
                                                                  <hr />
                                                            </div>
                                                            <span 
                                                                  class="d-flex align-items-center mx-3">
                                                                  <small class="text-muted mb-0"
                                                                        style="font-size: 25px;">Total
                                                                        Amount </small>
                                                                  <small 
                                                                        class="ml-auto fw-bolder mb-0"
                                                                        style="font-size: 31px;">{{
                                                                        (totalAmountToPaid)?.toLocaleString('en-IN', {
                                                                        minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                        }}
                                                                  </small>
      
                                                            </span>
                                                            <div *ngIf="this.tourtype == 'normal'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}/per-person
                                                                  </label>
                                                            </div>
                                                            <div *ngIf="this.tourtype == 'customized'" class="py-2 text-center">
                                                                  <label class="form-check-label"
                                                                        style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                        for="partial">
                                                                        Booking Amount - {{
                                                                        allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                        }}
                                                                  </label>
                                                            </div>
                                                      </div>
                                                </div>
                                               
                                                <div class="row text-center">
                                                      <div class="col-12">
                                                            <button type="submit" class="btn btn-primary ml-2 mr-2"
                                                                  style="margin-right: 0.5rem;"
                                                                  (click)="sendDataToGenerateInvoicerefund() ">Quotation</button>

                                                            <button type="submit" class="btn btn-info"
                                                                  (click)="booking('booknow')">Booking</button>
                                                            <button type="submit" class="btn btn-warning ml-2"
                                                                  (click)="booking('payonlink')">Booking by
                                                                  link</button>
                                                            <button type="submit" class="btn btn-secondary mr-2 ml-2"
                                                                  [routerLink]="['/add-user-list']">Cancel</button>
                                                      </div>
                                                </div>

                                          </div>
                                    </div>
                              </div>
                              <div class="row">

                              </div>
                              <div class="card-footer text-right">
                                    <!-- <app-button [type]="'submit'" [block]="false" [text]="'Booking'"
                                                [loading]="isAuthLoading"  [disabled]="isAuthDisabled">
                                          </app-button> -->
                                    <!-- <button type="button" [disabled]="isAuthDisabled"
                                                class="btn btn-secondary ml-2"
                                                [routerLink]="['/add-user-list']">Cancel</button> -->
                              </div>

                        </div>
                  </div>
            </div>

      </div>
</div>