<div class="content">
      <div class="container-fluid">
            <div  class="card card-primary">
                  <div *ngIf="this.pricingid" class="card-body">
                        <div class="tab-container">
                            <hr style="border-top: 1px solid black; width: auto;">
                            <hr style="border-top: 1px solid black; width: 0%;">
                    
                            <!-- Define tabs for each page with routerLinkActive directive -->
                            <div class="tab circular-button navbar-nav" routerLinkActive="active" [routerLink]="['/make-trip', this.object]" title="Make Trip">1</div>
                            
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-date', this.object]" title="Make Trip Date">2</div>
                    
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-overview', this.object]" title="Make Trip Overview">3</div>
                    
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-itinerary', this.object]" title="Make Trip Itinerary">4</div>
                            
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-inclusion', this.object]" title="Make Trip Inclusion">5</div>
                    
                            <hr style="border-top: 1px solid black; width: 156%;">
                            <hr style="border-top: 1px solid black; width: 500%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-pricing-list', this.object]" title="Make Trip Pricing">6</div><br>
                            
                            <hr style="border-top: 1px solid black; width: auto;">
                        </div>
                  </div> 
            </div>
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addInculssionForm" (ngSubmit)="addMakeTourFour(id)">
                                    <div class="card-body">
                                          <label for="class_name">Multiple Youtbube URL</label>
                                          <div *ngIf="i" class="col-md-4">
                                                <button type="button" class="btn btn-success mt-2"
                                                      (click)="addAdditionalInfo()"><i
                                                            class="fas fa-plus-circle"></i></button>
                                          </div>&nbsp;
                                          <div class="row" *ngFor="let group of additionalInfo.controls; let i = index;"
                                                formArrayName="multiple_url">
                                                <div class="col-md-12 ">

                                                      <div class="row form-group" [formGroupName]='i'>

                                                            <div class="col-md-6">
                                                                  <input type="text" class="form-control"
                                                                        placeholder="Youtube Url"
                                                                        formControlName="youtube_url">
                                                            </div>
                                                            <div class="col-md-2">
                                                                  <button type="button" class="btn btn-success"
                                                                        (click)="addAdditionalInfo()"><i
                                                                              class="fas fa-plus-circle"></i></button>&nbsp;
                                                                  <button type="button" class="btn btn-danger" *ngIf="i"
                                                                        (click)="removeAdditionalInfo(i)"><i
                                                                              class="fas fa-minus-circle"></i></button>
                                                            </div>
                                                            <div class="col-md-2">

                                                            </div>
                                                            <div class="col-md-2">

                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="row">
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Inclusion<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Inclusion" formControlName="inculssion">
                                                      </div>
                                                </div>
                                                <div class="col-md-12">
                                                      <div class="form-group">
                                                            <label for="class_name">Summary<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck" formControlName="summary">

                                                            </ckeditor>
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Exclusion<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Exclusion" formControlName="exculssion">
                                                      </div>
                                                </div>

                                                <div class="col-md-12">
                                                      <div class="form-group">
                                                            <label for="class_name">Summary<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck" formControlName="summaryExc">

                                                            </ckeditor>
                                                      </div>
                                                </div>

                                                




                                                <!-- <div class="form-group col-md-12" #div>
                                                </div>
                                                <div id="dynamic_fields"></div>
                                                <div class="row" style="margin-left: 1px;">
                                                      <div class="form-group col-md-12">
                                                            <button type="button" (click)="addElement()"
                                                                  class="btn btn-info">Add</button>
                                                      </div>
                                                </div> -->
                                          </div>

                                          <div class="col-md-12">
                                                <label for="class_name">Must Carry</label>
                                               
                                                <ckeditor [config]="config_ck"
                                                formControlName="mustCarry">
                                                </ckeditor>
                                          </div>
<!-- 
                                          <div class="col-md-6">
                                                <label for="class_name">Must Carry</label>
                                                <input type="text" class="form-control"
                                                placeholder="Must Carry"
                                                formControlName="mustCarry">
                                          </div> -->
                                          <br>

                                          <div class="col-md-12">
                                                <label for="class_name">Cancellation Policy</label>
                                               
                                                <ckeditor [config]="config_ck"
                                                formControlName="cancellationPolicy">
                                                </ckeditor>
                                          </div>
                                          <!-- <div class="col-md-6">
                                                <label for="class_name">Cancellation Policy</label>
                                                
                                                <input type="text" class="form-control"
                                                placeholder="Cancellation Policy"
                                                formControlName="cancellationPolicy">
                                          </div> -->

                                          <div class="col-md-12">
                                                <div class="form-group">
                                                      <label for="class_name">Inclusion Note<span
                                                                  class="required"></span></label>
                                                      <ckeditor [config]="config_ck" formControlName="otherinfo_note">

                                                      </ckeditor>
                                                </div>
                                          </div>

                                          <!-- <div class="col-md-12">
                                                <label for="class_name">Inclusion Note</label>
                                                <div class="form-group ">

                                                            <ckeditor [config]="config_ck" formControlName="otherinfo_note">
                                                             </ckeditor>
                  
                                                  </div>
                                          </div> -->

                                          <div class="col-md-4">
                                                <button type="button" class="btn btn-success mt-2"
                                                      (click)="addAdditionalInfonew()">
                                                      <i class="fas fa-plus-circle"></i>
                                                </button>
                                          </div>

                                          <br>

                                          <div class="row"
                                                *ngFor="let group of additionalInfonew.controls; let i = index;"
                                                formArrayName="inculssiontitledes">
                                                <div class="col-md-12">
                                                      <div class="row form-group" [formGroupName]='i'>

                                                            <div class="col-md-6">
                                                                  <label for="class_name">Inclusion Title</label>
                                                                  <input type="text" class="form-control"
                                                                  placeholder="Inclusion Title"
                                                                  formControlName="inculssion_title">
                                                            </div>

                                                            <div class="col-md-12">
                                                                  <label for="class_name">Inclusion
                                                                        Description</label>
                                                                 
                                                                  <ckeditor [config]="config_ck"
                                                                  formControlName="inculssion_desc">
                                                                  </ckeditor>
                                                            </div>

                                                            <div class="col-md-2">
                                                                  <button type="button"
                                                                        class="btn btn-success mt-2 "
                                                                        (click)="addAdditionalInfonew()">
                                                                        <i class="fas fa-plus-circle"></i>
                                                                  </button>&nbsp;
                                                                  <button type="button"
                                                                        class="btn btn-danger mt-2"
                                                                        (click)="removeAdditionalInfonew(i)">
                                                                        <i class="fas fa-minus-circle "></i>
                                                                  </button>
                                                            </div>

                                                      </div>
                                                </div>
                                          </div>



                                          <!-- <div *ngIf="i" class="col-md-4">
                                                <button type="button" class="btn btn-success mt-2"
                                                      (click)="addAdditionalInfonew()"><i
                                                            class="fas fa-plus-circle"></i></button>
                                          </div> -->

                                          <!-- <div class="row"
                                                *ngFor="let group of additionalInfonew.controls; let i = index;"
                                                formArrayName="inculssiontitledes">
                                                <div *ngIf="i" class="col-md-12 ">
                                                      <div class="row form-group" [formGroupName]='i'>

                                                            <div class="col-md-6">
                                                                  <label for="class_name">Inclusion Title</label>
                                                                  <input type="text" class="form-control"
                                                                        placeholder="Inclusion Title "
                                                                        formControlName="inculssion_title">
                                                            </div>
                                                          
                                                            &nbsp;
                                                            <div class="col-md-12">
                                                                  <label for="class_name">Inclusion
                                                                        Description</label>
                                                                  <ckeditor [config]="config_ck"
                                                                        formControlName="inculssion_desc">
                                                                  </ckeditor>
                                                                
                                                            </div>

                                                            <div class="col-md-2">

                                                                  <button type="button" class="btn btn-success mt-2"
                                                                        (click)="addAdditionalInfonew()"><i
                                                                              class="fas fa-plus-circle"></i></button>&nbsp;
                                                                  <button type="button" class="btn btn-danger mt-2"
                                                                        *ngIf="i"
                                                                        (click)="removeAdditionalInfonew(i)"><i
                                                                              class="fas fa-minus-circle "></i></button>
                                                            </div>
                                                            <div class="col-md-2">

                                                            </div>
                                                            <div class="col-md-2">

                                                            </div>
                                                      </div>
                                                </div>
                                          </div> -->

                                    </div>
                                    <!-- <div class="card-body" style="margin-top: -42px;">
                                          <div class="row">
                                                <div class="col-md-4">

                                                      <div class="custom-control custom-checkbox">
                                                            <input id="male" type="checkbox"
                                                                  class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                  for="male">Status</label>
                                                      </div>
                                                </div>
                                          </div>
                                    </div> -->
                                    <!-- /.card-body -->
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Next'"
                                                [loading]="isAuthLoading" [color]="'info'"
                                                [disabled]="isAuthDisabled"></app-button>
                                          <button type="submit" class="btn btn-info"
                                                [routerLink]="['/make-trip-itinerary', tripId]"
                                                style="float:left;">Previous</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>