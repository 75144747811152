<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>User Name</th>
                  <th>Tour Amount</th>
                  <th>Amount Paid</th>
                  <th>Remaining Amount </th>
                </tr>
              </thead>
              <tbody *ngIf="allData?.length != 0">
                  <tr *ngFor="let data of allData?.data; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ data?.applyTour_id?.user_id?.firstname | titlecase }} {{ data?.applyTour_id?.user_id?.lastname | titlecase }}</td>
                    <td>{{data?.applyTour_id?.finalPrice + data?.applyTour_id?.addons_id}}</td>
                    <td>{{data?.applyTour_id?.amountPaid}}</td>
                    <td>{{data?.applyTour_id?.finalPrice - data?.applyTour_id?.amountPaid + data?.applyTour_id?.addons_id}}</td>
                
                </tr>
              </tbody>
              <tbody *ngIf="allData?.data?.length == 0">
                <tr>
                  <td colspan="3" class="no-data-available">No data!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</section>
