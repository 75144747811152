import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { AppService } from '../../utils/services/app.service';

@Component({
  selector: 'app-add-menu-master',
  templateUrl: './add-menu-master.component.html',
  styleUrls: ['./add-menu-master.component.scss']
})
export class AddMenuMasterComponent implements OnInit {
  addMenuMasterForm: FormGroup;
  allData: any;
  private menumasterid: any;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
   
 /*   uploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
    filebrowserBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
    filebrowserImageBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
    filebrowserUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',*/
  };
  slug_url: any;

  constructor(  private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private appService: AppService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private ngselectmodule: NgSelectModule,
    private route: ActivatedRoute) { }
  @ViewChild('importFestImg', { static: false }) importFestImg: ElementRef;
  ngOnInit(): void {
    this.addMenuMasterForm = new FormGroup({
      menu: new FormControl(),
      title: new FormControl(),
      tagline: new FormControl(),
      image: new FormControl(),
      parentcategory: new FormControl(),
      aboutus: new FormControl(),
      status: new FormControl(),
      isfeaturetour: new FormControl(),
    });
    this.addMenuMasterForm = this.formBuilder.group({
      menu: ['', [Validators.required]],
      slug_url: ['', [Validators.required]],
      title: ['', [Validators.required]],
      tagline: ['', [Validators.required]],
      image: ['', Validators.required],
      parentcategory: ['', Validators.required],
      aboutus: ['', [Validators.required]],
      status: [false],
      isfeaturetour: [0]
    });
    this.menumasterid = this.route.snapshot.paramMap.get('menumaster_id');
    if ( 
      this.menumasterid != undefined &&
      this.menumasterid != null &&
      this.menumasterid != ''
    ) {
      this.editmenumaster(this.menumasterid);
    } else {
      this.addMenuMasterForm.get('status').setValue('active')
      this.addMenuMasterForm.get('isfeaturetour').setValue('1')
    }
    this.menu()
  }

  editmenumaster(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.addmenumasterById(dataForForm,id).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) { 
        console.log(result.getData, 'hiiiiiiiiiii');
        //localStorage.setItem('menumaster_id',result.getData.parentcategory)

        let menuData: any = result.getData;
         this.slug_url = menuData.menu.replace(/ /g, '-');
        console.log(this.slug_url);
        
        this.addMenuMasterForm.patchValue({
          
          menu: menuData.menu,
          slug_url: this.slug_url,
          title: menuData.title,
          tagline: menuData.tagline,
          image: menuData.image[0]?.src,
          aboutus: menuData.aboutus,
          //parentcategory: menuData.parentcategory,
        });
        console.log(this.addMenuMasterForm.value.slug_url);

       // CKEDITOR.instances[this.instance.name].setData(menuData.description);
        if (menuData.status == 'active') {
          this.addMenuMasterForm.get('status').setValue('active');
        }
        if (menuData.isfeaturetour == '1') {
          this.addMenuMasterForm.get('isfeaturetour').setValue('1');
        }
        setTimeout(() => {
          this.importFestImg.nativeElement.innerText =
            menuData.image[0]?.orgName;
        }, 1000);
      } else {
        this.toastr.error(result.message);
      }
    });
  }

  menu() {
    this.apiService.addMenulist().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
    });
  }
  
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addMenuMasterForm.get('image').setValue(file);
      this.importFestImg.nativeElement.innerText = event.target.files[0].name;
    }
  }

  async addmastermenu(){
    console.log('Hello')
    if (
      this.addMenuMasterForm.value.menu.trim() == undefined ||
      this.addMenuMasterForm.value.menu.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter menu',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMenuMasterForm.value.title.trim() == undefined ||
      this.addMenuMasterForm.value.title.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter title',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMenuMasterForm.value.tagline.trim() == undefined ||
      this.addMenuMasterForm.value.tagline.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter tagline',
        icon: 'warning',
      });
      return false;
    }
    // if (
    //   this.addMenuMasterForm.value.parentcategory.trim() == undefined ||
    //   this.addMenuMasterForm.value.parentcategory.trim() == ''
    // ) {
      
    //   Swal.fire({
    //     text: 'Please enter sub category',
    //     icon: 'warning',
    //   });
    //   return false;
    // }
    //console.log(this.menumasterid)
    if (this.menumasterid != undefined && this.menumasterid != '') {
      this.updatemenu(this.menumasterid);
    } else {
      if (
        this.addMenuMasterForm.value.image == undefined ||
        this.addMenuMasterForm.value.image == ''
      ) {
        Swal.fire({
          text: 'Please enter image',
          icon: 'warning',
        });
        return false;
      } 
      let formData = new FormData();
      formData.append('menu', this.addMenuMasterForm.get('menu').value);
      formData.append('slug_url', this.addMenuMasterForm.get('menu').value);
      formData.append('title', this.addMenuMasterForm.get('title').value);
      formData.append('tagline', this.addMenuMasterForm.get('tagline').value);
      formData.append('image', this.addMenuMasterForm.get('image').value);
      //formData.append('parentcategory', this.addMenuMasterForm.get('parentcategory').value);
      formData.append('aboutus', this.addMenuMasterForm.get('aboutus').value);
      formData.append('status', this.addMenuMasterForm.get('status').value);
      formData.append('isfeaturetour', this.addMenuMasterForm.get('isfeaturetour').value);
      console.log(this.addMenuMasterForm.value)
      console.log(formData)
      //return;
      console.log(formData);
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      let catTypeExAddApi: any;
      await this.apiService.addmenumaster(formData).subscribe((result: any) => {
        console.log(result)
        if (result.success == true) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: result.message,
            icon: 'success',
          });
          //this.toastr.success(result.message);
          this.router.navigateByUrl('/add-menu-master-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: result.message,
            icon: 'error',
          });
          //this.toastr.error(result.message);
        }
      });
    }
  }

  async updatemenu(id) {
    console.log('dd')
    let formData = new FormData();
    console.log(this.addMenuMasterForm.value.image?.name);

    if (
      this.addMenuMasterForm.value.image?.name != undefined
      //  ||
      //  this.addMenuMasterForm.value.image?.name != ''
    ) {
      //console.log('kalu');

      formData.append('image', this.addMenuMasterForm.get('image').value);
    }

    formData.append(
      'status',
      this.addMenuMasterForm.get('status').value == '' ||
        this.addMenuMasterForm.get('status').value == 'inactive'
        ? 'inactive'
        : 'active'
    );
    formData.append(
      'isfeaturetour',
      this.addMenuMasterForm.get('isfeaturetour').value == '' ||
        this.addMenuMasterForm.get('isfeaturetour').value == '0'
        ? '0'
        : '1'
    );
    // formData.append('user_id', this._userId);
    formData.append('menu', this.addMenuMasterForm.get('menu').value);
    formData.append('slug_url', this.addMenuMasterForm.get('menu').value);
    formData.append('title', this.addMenuMasterForm.get('title').value);
    formData.append('tagline', this.addMenuMasterForm.get('tagline').value);
    formData.append('aboutus', this.addMenuMasterForm.get('aboutus').value);
    //formData.append('parentcategory', this.addMenuMasterForm.get('parentcategory').value);
    // console.log(formData);
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    await this.apiService.updateMenuMaster(formData, id).subscribe((result: any) => {
      if (result.success) {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        Swal.fire({
          text: result.message,
          icon: 'success',
        });
        //this.toastr.success(result.message);
        this.router.navigateByUrl('/add-menu-master-list');
      } else {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        Swal.fire({
          text: result.message,
          icon: 'error',
        });
        //this.toastr.error(result.message);
      }
    });
  }
}
