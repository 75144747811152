import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';


import Swal from 'sweetalert2';

@Component({
  selector: 'app-make-trip-date',
  templateUrl: './make-trip-date.component.html',
  styleUrls: ['./make-trip-date.component.scss']
})
export class MakeTripDateComponent implements OnInit {
  addMakeDateForm: FormGroup
  public selectFinancialyear: any;
  public getFinancialYear: Array<any> = [];
  public value: boolean = false;
  tableRowCount: number = 0;
  endSetCount: number = 0;
  previousStartDate: Date | null = null;
  previousEndDate: Date | null = null;
  todayDate: any;
  reminderDays: number[] = Array.from({ length: 30 }, (_, i) => i + 1);
  public object: any
  data: any;
  private _userId: string = localStorage.getItem('id');
  isAdd: boolean = false;
  index: number = 1;
  dateDiffer: any;
  message: string[] = [];
  public isAuthLoading = false;
  public isAuthDisabled = false;
  count: any
  id: any;
  tableData: any[] = [];
  formBuilder: any;
  start_date: string;
  end_date: string;
  minEndDate: any;
  DateArr: any[] = [];
  diffDays: any;
  nightorday: any;
  differ: number
  minStartDate: any;
  pricingid: any;
  touttype: any;
  batchNumber: any;
  

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }


  @ViewChild('div') div: ElementRef;

  ngOnInit(): void {
   
    this.selectFinancialyear = "Select Year"
    //this.selectFinancialyear = "Select Year"
    var currentYear = new Date().getFullYear();
    for(var i = 0; i < 5; i++){
      var next = currentYear+1;
      var year = currentYear + '-' + next.toString().slice(-2);
      // $('#financialYear').append(new Option(year, year));
      this.getFinancialYear.unshift(year)
      currentYear--;
    }
    this.object = this.route.snapshot.paramMap.get('objId');
    if (this.object != undefined && this.object != null && this.object != '') {
      this.edittripdate(this.object);
    }
    let getDateOnly = new Date().toISOString().split('T')[0];
    this.todayDate = getDateOnly;
    this.addMakeDateForm = this.fb.group({
      start_date1: new FormControl({ value: '', disabled: false }, Validators.required),
      end_date1: new FormControl({ value: '', disabled: false }, Validators.required),
      label: new FormControl(''),
      show_date: new FormControl(true),
      dayornight: new FormControl(Validators.required),
      lastReminderDay: new FormControl( 1,Validators.required),
      nightorday: new FormControl({ value: '', disabled: true }, Validators.required),
    });

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    this.addMakeDateForm.get('start_date1').setValue(formattedDate);
    //this.addMakeDateForm.get('dayornight').setValue('')
    //this.setDayOrNight(1);
    //this.addPricingForm.get('status').setValue('active');

  }

  setDayOrNight(day: number) {
    this.addMakeDateForm.patchValue({
      dayornight: day
    });
  }

  updateTableData() {
    const start_date = new Date(this.addMakeDateForm.get('start_date1').value);
    if (this.previousStartDate && this.previousEndDate && this.endSetCount >= 1) {
      const timeDiff = start_date.getTime() - this.previousStartDate.getTime();
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.dateDiffer = diffDays;
      const end_date = new Date(this.previousEndDate);
      end_date.setDate(end_date.getDate() + diffDays);
      this.addMakeDateForm.get('end_date1').setValue(end_date.toISOString().slice(0, 10));
    }
    if (this.differ > 0) {
      start_date.setDate(start_date.getDate() + this.differ);
      this.addMakeDateForm.get('end_date1').setValue(start_date.toISOString().slice(0, 10));
    }
    this.previousStartDate = start_date;
  }



  updateNightOrDay(): void {
    const start_date = new Date(this.addMakeDateForm.get('start_date1').value);
    const end_date = new Date(this.addMakeDateForm.get('end_date1').value);
    const timeDiff = Math.abs(end_date.getTime() - start_date.getTime());
    const diff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    this.count = diff
    this.addMakeDateForm.patchValue({
      nightorday: diff
    })
  }

  checkDates() {
    console.log('enterd checkdate');
    const start = new Date(this.addMakeDateForm.get('start_date1').value);
    start.setDate(start.getDate() + 1); 
    this.minEndDate = start.toISOString().split('T')[0]; 
  }


  addRow() {
    const start_date = this.addMakeDateForm.get('start_date1').value;
    const end_date = this.addMakeDateForm.get('end_date1').value;
    const label = this.addMakeDateForm.get('label').value;
    const show_date = this.addMakeDateForm.get('show_date').value;
    //const nightorday = this.addMakeDateForm.get('nightorday').value;
    const alreadyExists = this.tableData.some(row =>
      row.start_date === start_date && row.end_date === end_date
    );

    if (alreadyExists) {
      Swal.fire({
        text: 'The selected start and end dates already exist in the table.',
        icon: 'warning',
      });
      return;
    }
    this.tableData.push({ start_date, end_date, label, show_date });
    this.endSetCount++;
    this.previousEndDate = new Date(end_date);
    this.tableRowCount++;
    this.updateEndDateInputState();
    //this.submitForm(this.object)
  }


  removeRow(index: number) {
    this.tableData.splice(index, 1);
    this.tableRowCount--;
    this.updateEndDateInputState();
    if (this.tableData?.length == 0) {
      console.log('dfvjkdgbjk');
      this.addMakeDateForm.get('end_date1').enable();
      this.previousEndDate = null;
      this.differ = 0
    }
  }

  private updateEndDateInputState() {
    if (this.tableData?.length == 0) {
      this.addMakeDateForm.get('end_date1').enable();
    } else {
      this.addMakeDateForm.get('end_date1').disable();
    }
  }

  edittripdate(id: any) {
    let dataForForm = {
      id: id,
    };
    this.spinner.show()
    this.apiService.tourUpdate(dataForForm).subscribe(
      (result: any) => {
        console.log(result)
        if (result.success) {
          this.spinner.hide()
          this.touttype = result?.getData?.tourtype
          this.pricingid = result?.getData?.bookPrice;
          this.addMakeDateForm.get('end_date1').disable();
          this.tableData = result?.getData?.DateArr;
          this.batchNumber = result?.getData?.DateArr[0]?.batchNumber
        console.log(this.batchNumber)
          if (this.tableData?.length == 0) {
            this.addMakeDateForm.get('end_date1').enable();
          }
          this.differ = result?.getData?.nightorday
          console.log(this.differ)
          this.addMakeDateForm.patchValue({
            dayornight: result?.getData?.nightorday,
            lastReminderDay: result?.getData?.lastReminderDay
          })
        } else {
          this.spinner.hide()
          console.error('API request was not successful:', result.message);
        }
      },
      (error) => {
        console.error('Error occurred during API request:', error);
      }
    );
  }


  submitForm(id: any) {
    //return
    if(this.touttype == 'normal'){
      if (this.tableData.length === 0) {
        Swal.fire({
            text: 'Please add Dates to the table before submitting.',
            icon: 'warning',
        });
        return; // Exit the function if table data is empty
      }
      // this.showLoader('Please Wait')
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      let DateArrnew: any[] = [];
      this.tableData.forEach((row, index) => {
        let DateArr = {
          'start_date': row.start_date,
          'end_date': row.end_date,
          'label': row?.label,
          'show_date': row?.show_date,
          //'nightorday': row?.nightorday,
        };
        DateArrnew.push(DateArr);

      });
      let dataToSend = {
        DateArr: DateArrnew,
        lastReminderDay: this.addMakeDateForm.value.lastReminderDay,
        nightorday: this.addMakeDateForm.get('nightorday').value ?  this.addMakeDateForm.get('nightorday').value : this.differ
        //row.label ? row.label : 'No Label'
      };
      console.log(dataToSend)
    // return
    this.spinner.show();
    this.apiService.updateTourdate(dataToSend, this.object).subscribe((result: any) => {
      if (result.success) {
        this.spinner.hide()
        this.dismissLoader()
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.router.navigateByUrl('/make-trip-overview/' + this.object);
        // window.location.reload();
      } else {
        this.spinner.hide()
        this.dismissLoader()
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        Swal.fire({
          text: result.message,
          icon: 'error',
        });
      }
    });
    }else{
      let dataToSend = {
        start_date_1: this.addMakeDateForm.get('start_date1').value,
        lastReminderDay: this.addMakeDateForm.value.lastReminderDay,
        nightorday: this.addMakeDateForm.value.dayornight
      };
    console.log(this.addMakeDateForm.value)
    //return
    this.spinner.show();
    this.apiService.updateTourdatecusto(dataToSend, this.object).subscribe((result: any) => {
      if (result.success) {
        this.spinner.hide()
        this.dismissLoader()
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.router.navigateByUrl('/make-trip-overview/' + this.object);
        // window.location.reload();
      } else {
        this.spinner.hide()
        this.dismissLoader()
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        Swal.fire({
          text: result.message,
          icon: 'error',
        });
      }
    });

    }
    
  }



  financialList(){
    console.log(this.value, this.selectFinancialyear)
    if(this.selectFinancialyear == 'Select Year'){
      Swal.fire({
        text: 'Please select financial year',
        icon: 'warning',
      });
      return
    }
    this.spinner.show();
    let data = {
      name: this.selectFinancialyear,
      tour_id : this.object
    };
    this.apiService.dateBYbatchsearch(data).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res)
      if(res.success){
        this.tableData = res?.getData[0]?.DateArr
        
        console.log(this.tableData)
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    })
  }


  updateCheckbox(checked: boolean, index: number) {
    this.tableData[index].show_date = checked;;
  }

  showLoader(text: string) {
    Swal.fire({
      text: text,
      allowOutsideClick: false,
      showConfirmButton: false,
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  async dismissLoader() {
    Swal.close();
    setTimeout(() => Swal.close(), 1000);
  }

}

