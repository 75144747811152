<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table 
              datatable 
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" 
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>User Name</th>
                  <th>Mobile No.</th>
                  <th>Tour Name</th>
                  <th>Remaining Amount</th>
                  <th>Order Status</th>
                  <th>Link Amount</th>
                  <th>Link Valid Upto</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allOrdersWithLink?.length != 0">
                <tr *ngFor="let ordersWithLink of allOrdersWithLink; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ ordersWithLink.user_id?.firstname | titlecase }} {{ ordersWithLink.user_id?.lastname | titlecase }}</td>
                  <td>{{ ordersWithLink.user_id?.mobile }}</td>
                  <td>{{ ordersWithLink.tour_id?.title }}</td>
                  <!-- <td>{{ ordersWithLink.totalAmount - ordersWithLink.amountPaid }}{{ordersWithLink.addons_id ? ' (Addon ' + ordersWithLink.addons_id + ')' : ''}}</td> -->
                  <td>{{ ordersWithLink.addons_id ? ordersWithLink.totalAmount + ordersWithLink.addons_id - ordersWithLink.amountPaid: ordersWithLink.totalAmount - ordersWithLink.amountPaid}}</td>
                  <td>{{ ordersWithLink.payment_status }}</td>
                  <td>{{ ordersWithLink.payLinkAmount }}</td>
                  <td>{{ ordersWithLink.payLinkValidTill | date :'dd/MM/yy h:mm:ss a' }}</td>
                  <td>
                    <ng-container *ngIf="checkValidity(ordersWithLink.payLinkValidTill); else openCopyBtn">
                        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#pay-link" (click)="getOrderId(ordersWithLink._id)">
                          <i class="fa fa-undo" style="scale: 0.75"></i>
                        </button>
                    </ng-container>
                    <ng-template #openCopyBtn>
                        <button type="button" class="btn btn-info" (click)="copyShareLink('https://glabol.swapinfotech.com/afterpay/'+ordersWithLink.payLink)">
                          <i class="fa fa-copy" style="scale: 0.75"></i>
                        </button>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allOrdersWithLink?.length == 0">
                <tr>
                  <td colspan="3" class="no-data-available">No data!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="pay-link" role="dialog">
        <div class="modal-dialog modal-sm modal_div">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">REVIVE PAY LINK</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="linkValidTill">Link valid till</label>
                                <select id="linkValidTill" class="form-control" name="linkValidTill"  [(ngModel)]="linkValidTill">
                                    <option *ngFor="let val of validity" [selected]="val===1" value="{{val}}">{{val}} hour</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-success btn-block" (click)="sendLink()">Send link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  