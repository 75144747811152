import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  addWebForm: FormGroup;
  public readOnly: boolean = false;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  public cancelCatRoute: string;
  private adminid: any;
  private status: any;
  public submitted: boolean = false;
  user: any;
  allStates: any = [];
  public maxDate = new Date().toISOString().slice(0, 10);

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {
    this.addWebForm = this.formBuilder.group({
      //user_role: this.formBuilder.array([], [Validators.required])
    });
  }

  ngOnInit(): void {
    this.addWebForm = new FormGroup({
      firstname: new FormControl(),
      lastname: new FormControl(),
      mobile: new FormControl(),
      email: new FormControl(),
      role: new FormControl(),
      status: new FormControl(),
      address: new FormControl(),
      age: new FormControl(),
      gender: new FormControl(),
      DOB: new FormControl(),
      city: new FormControl(),
    });
    this.addWebForm = this.formBuilder.group({
      firstname: ['', [Validators.required , Validators.minLength(3)]],
      lastname: ['', [Validators.required , Validators.minLength(3)]],
      mobile: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      role: ['', [Validators.required]],
      status: ['', [Validators.required]],
      email: ['', [Validators.required]],
      state: ['', [Validators.required]],
      address: [''],
      age:[''],
      gender: [''],
      DOB: [''],
      city: ['']
    });
    this.addWebForm.get('status').setValue('active');
    this.getAllStates();
  }

  get f() {
    return this.addWebForm.controls;
  }

  getAllStates() {
    this.apiService.getStates().subscribe((res: any) => {
      // console.log(res);
      const result = res.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      // console.log(result);
      this.allStates = result;
      this.addWebForm.get('state').setValue('');
    });
  }

  setUser() {
    if (
      this.addWebForm.value.firstname == undefined ||
      this.addWebForm.value.firstname.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter first name',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addWebForm.value.lastname == undefined ||
      this.addWebForm.value.lastname.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter last name',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addWebForm.value.mobile == undefined ||
      this.addWebForm.value.mobile == ''
    ) {
      Swal.fire({
        text: 'Please enter mobile number',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addWebForm.value.email == undefined ||
      this.addWebForm.value.email.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter email',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addWebForm.value.state == undefined ||
      this.addWebForm.value.state.trim() == ''
    ) {
      Swal.fire({
        text: 'Please select state',
        icon: 'warning',
      });
      return false;
    }

    if (this.addWebForm.status == 'active') {
      this.addWebForm.get('status').setValue('active');
    }
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.addWebForm.value.role = 2;
    this.addWebForm.value.is_register = true;
    this.spinner.show();
    console.log(this.addWebForm.value)
    this.apiService.webSite(this.addWebForm.value).subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        this.spinner.hide();
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
        //this.toastr.success(res.message);
        this.router.navigateByUrl('/add-user-list');
      } else {
        this.spinner.hide();
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }
  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }
}
