<div class="content">
    <div class="container-fluid">

        <div class="card card-primary">
            <div class="card-body">
                <div class="tab-container">
                    <hr style="border-top: 1px solid black; width: auto;">
                    <hr style="border-top: 1px solid black; width: 0%;">

                    <!-- Define tabs for each page with routerLinkActive directive -->
                    <div class="tab circular-button navbar-nav" routerLinkActive="active"
                        [routerLink]="['/make-trip', this.object]" title="Make Trip">1</div>

                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">

                    <div class="tab circular-button" routerLinkActive="active"
                        [routerLink]="['/make-trip-date', this.object]" title="Make Trip Date">2</div>

                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">

                    <div class="tab circular-button" routerLinkActive="active"
                        [routerLink]="['/make-trip-overview', this.object]" title="Make Trip Overview">3</div>

                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">

                    <div class="tab circular-button" routerLinkActive="active"
                        [routerLink]="['/make-trip-itinerary', this.object]" title="Make Trip Itinerary">4</div>

                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">

                    <div class="tab circular-button" routerLinkActive="active"
                        [routerLink]="['/make-trip-inclusion', this.object]" title="Make Trip Inclusion">5</div>

                    <hr style="border-top: 1px solid black; width: 156%;">
                    <hr style="border-top: 1px solid black; width: 500%;">

                    <div class="tab circular-button" routerLinkActive="active"
                        [routerLink]="['/make-trip-pricing-list', this.object]" title="Make Trip Pricing">6</div><br>

                    <hr style="border-top: 1px solid black; width: auto;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- form start -->
                    <form [formGroup]="addPricingForm" (ngSubmit)="addPrice(id)">
                        <div class="card-body">
                            <div *ngIf="this.addboard?.tourtype == 'normal'" class="row">
                                <div class="col-md-4">
                                    <div class="form-group" *ngIf="allDates?.start_date.length > 0">
                                        <label for="class_name">Select Batch<span class="required"></span></label>
                                        <select id="slct" class="form-control" required="required" #dateSchedule
                                            formControlName="dateScheduletrip" (change)="onChange($event.target.value)">
                                            <option value="" selected="selected">
                                                Select option
                                            </option>
                                            <option value="{{ date._id }}"
                                                *ngFor="let date of this.allData.select_batch; index as i">
                                                Batch - {{ i+1 }}:
                                                {{ date.
                                                start_date
                                                | date: 'dd/MM/yyyy'
                                                }} - {{
                                                date.end_date
                                                | date: 'dd/MM/yyyy' }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label for="class_name">GST</label>
                                    <div class="form-group">
                                        <div class="ml-4">
                                            <input class="form-check-input" type="radio" name="gst"
                                                id="flexRadioDefault1" value="inclusive" checked=[true]
                                                formControlName="gst">
                                            <label class="form-check-label">
                                                Inclusive
                                            </label>
                                            <input class="form-check-input  ml-4" type="radio" name="gst"
                                                id="flexRadioDefault1" value="exclusive" formControlName="gst">
                                            <label class="form-check-label ml-5">
                                                Exclusive
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4" *ngIf="allData?.domesticorInternational==='international'">
                                    <label for="class_name">TCS</label>
                                    <div class="form-group">
                                        <div class="ml-4">
                                            <input class="form-check-input" type="radio" name="tcs" id="inclusiveTCS"
                                                value="inclusive" checked=[true] formControlName="tcs"
                                                >
                                            <label class="form-check-label" for="inclusiveTCS">Inclusive</label>

                                            <input class="form-check-input ml-4" type="radio" name="tcs"
                                                id="exclusiveTCS" value="exclusive" formControlName="tcs"
                                                >
                                            <label class="form-check-label ml-5" for="exclusiveTCS">Exclusive</label>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-md-4" *ngIf="allData?.domesticorInternational==='international'">
                                    <label for="class_name">GST</label>
                                    <div class="form-group">
                                        <div class="ml-4">
                                            <input class="form-check-input" type="radio" name="gst" id="inclusiveGST"
                                                value="inclusive" checked=[true] formControlName="gst" (change)="onGSTChange($event)">
                                            <label class="form-check-label" for="inclusiveGST">Inclusive</label>

                                            <input class="form-check-input ml-4" type="radio" name="gst"
                                                id="exclusiveGST" value="exclusive" formControlName="gst"
                                                (change)="onGSTChange($event)">
                                            <label class="form-check-label ml-5" for="exclusiveGST">Exclusive</label>
                                        </div>
                                    </div>
                                </div> -->
                                



                                <div class="col-md-4">
                                    <div class="form-group">
                                        <!-- <h3>Pricing List</h3> -->
                                        <label for="class_name">Booking Price <span class="required"></span></label>
                                        <input type="text" class="form-control" placeholder="Booking Price"
                                            (keypress)="onlyNumberKey($event)" formControlName="bookPrice">
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="this.AllTourData?.infantfrom">
                                    <div class="form-group">
                                        <label for="class_name">Infant Price </label>
                                        <input type="text" class="form-control" placeholder="Infant Price"
                                            (keypress)="onlyNumberKey($event)" formControlName="infant_price">
                                    </div>
                                </div>

                                <div class="col-md-4" *ngIf="this.AllTourData?.childfrom">
                                    <div class="form-group">
                                        <label for="class_name">Child Price  </label>
                                        <input type="text" class="form-control" placeholder="Child Price"
                                            (keypress)="onlyNumberKey($event)" formControlName="child_price">
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Coupon</label>
                                        <select class="form-control" formControlName="coupon_code">
                                            <option value="" selected>Select Coupon
                                            </option>
                                            <option *ngFor="let item of allcoupon; let i = index" value="{{item._id}}">
                                                {{item.coupon_code}} </option>
                                        </select>

                                    </div>
                                </div> -->

                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-4" *ngIf="pck?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Package Type</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of pck; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span
                                                                class="required"></span></label>
                                                        <!-- <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="pck_strike[i]"
                                                        placeholder="Enter Striking Price"> -->
                                                        <input type="text" class="form-control board"
                                                            (keypress)="onlyNumberKey($event)" [formControlName]="data"
                                                            placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-md-6 mb-4" *ngIf="pck_strike?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Striker Package Type</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let strikeData of pck_strike; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class">{{ strikeData }}<span
                                                                class="required"></span></label>
                                                        <!-- <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]=""
                                                        placeholder="Enter Striking Price"> -->
                                                        <input type="text" class="form-control board"
                                                            (keypress)="onlyNumberKey($event)"
                                                            [formControlName]="strikeData"
                                                            placeholder="Enter Striking Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-md-6 mb-4" *ngIf="rooms?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Room Sharing </th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of rooms; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span
                                                                class="required"></span></label>
                                                        <input type="text" class="form-control board"
                                                            (keypress)="onlyNumberKey($event)" [formControlName]="data"
                                                            placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <!-- </div> -->
                                <!-- <div class="row mb-4"> -->
                                <!-- <div class="col-md-6 mb-4" *ngIf="share?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Shared bikes</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of share; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span class="required"></span></label>
                                                        <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="data+'_share'"
                                                        placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-md-6 mb-4" *ngIf="solo?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Solo bikes</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of solo; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span class="required"></span></label>
                                                        <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="data+'_solo'"
                                                        placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead>
                                            <tr>
                                                <th style="text-align: center;">S. No.</th>
                                                <th style=" text-align: center;">Boarding Point</th>
                                                <th style="text-align: center;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="board?.length != 0">
                                            <tr *ngFor="let boardData of board; let i = index ">
                                                <td style="text-align: center;">{{ i + 1 }}</td>
                                                <td style="text-align: center;">{{ boardData }}</td>
                                                <td style="text-align: center;"><input class="form-check-input"
                                                        type="radio" [formControlName]="boardData"
                                                        (change)="toggleShow('single'+i, i)" value="single{{i}}"
                                                        style=" margin-left:5px;margin-top: 28px;">
                                                    <label class="form-check-label"
                                                        style=" margin-left:18px; margin-top: 20px;">
                                                        Single Boarding Point
                                                    </label>
                                                    <div *ngIf="isShown.includes('single'+i)" class="row" id="divshow">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="class_name">Board
                                                                    Place<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'boardplace'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>

                                                </td> -->

                                                <td style="text-align: center;"><input class="form-check-input"
                                                        type="radio" [formControlName]="boardData" value="multi{{i}}"
                                                        (change)="toggleShow('multi'+i, i)"
                                                        style=" margin-left:17px;margin-top: 28px;">
                                                    <label class="form-check-label"
                                                        style=" margin-left:35px;margin-top: 20px;">
                                                        Multiple Boarding Point
                                                    </label>
                                                    <div *ngIf="isShown.includes('multi'+i)" class="row" id="divshow">
                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    Sleeper <span class="required"></span></label>
                                                                <input type="text" [formControlName]="'Sleeper'+i"
                                                                    class="form-control board"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    3 AC<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'3AC'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name" style="text-align: center;">
                                                                    2 AC<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'2AC'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    1 AC<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'1AC'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    Flight<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'Flight'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>

                                                </td> -->

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group ">

                                  <label for="class_name">Tour Details Note<span
                                    class="required"></span></label>
                                  <ckeditor [config]="config_ck" formControlName="tourDetailsNote">

                                  </ckeditor>

                              </div>
                      </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">

                                    <div class="custom-control custom-checkbox">
                                        <input id="male" type="checkbox" class="custom-control-input"
                                            formControlName="status"
                                            (change)="addPricingForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                            [checked]="addPricingForm.value.status == 'active'" />
                                        <label class="custom-control-label" for="male">Status</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer text-right">

                            <app-button [type]="'submit'" style="margin-right: 10px;" [block]="false" [text]="'Submit'" [loading]="isAuthLoading"
                                [color]="'info'" [disabled]="isAuthDisabled"></app-button>
                                <button type="submit" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="['/make-trip-list']"
                                style="float:right;">Cancel</button>
                            <button type="submit" class="btn btn-info" [routerLink]="['/make-trip-inclusion', this.object]"
                                style="float:left;">Previous</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>