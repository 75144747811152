import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { environment } from '../../../environments/environment';
declare const window: any;
@Component({
  selector: 'app-make-trip-itinerary',
  templateUrl: './make-trip-itinerary.component.html',
  styleUrls: ['./make-trip-itinerary.component.scss'],
})
export class MakeTripItineraryComponent implements OnInit {
  addItineraryForm: FormGroup = this.formBuilder.group({
      days: ['', [Validators.required]],
      allDateData: ['', [Validators.required]],
      Itinerary: ['', [Validators.required]],
      tourOutlineNote: ['', [Validators.required]],
      user_role: this.formBuilder.array([], [Validators.required]),
      multiple_itinerarydesc: this.formBuilder.array([this.addAdditionalInfoGroup()])
    });
  id: any;
  object: any;
  apiHost: string;
  @ViewChild('div', { static: false }) div: ElementRef;
  date: any;
  allData: any;
  allPackData: any;
  allDateData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public i: number = 1;
  public dynamicDesc: any = [];
  pck: any;
  TripData: any;
  start_date: any;
  formattedDate: any
  images: any
  formattedDateenddate: any;
  public minDate = undefined;
  public allDates: any;
  tripId: any;
  propertyLists: any = {};
  allProps = ['breakfast', 'lunch', 'dinner', 'evening_snacks', 'monument_fee', 'bonfire', 'dJ_night', 'live_music', 'movie_screening', 'bike_rent', 'riverrafting', 'paragliding', 'water_activities', 'jeep_safari', 'camel_ride'];
  pricingid: any;
  itineraryImages :  any = [];
  itineraryData: any;
  
  //Add Fields

  //Add Fields
  addAdditionalInfo(): void {
    this.additionalInfo.push(this.addAdditionalInfoGroup());
  }

  //Remove Fields
  removeAdditionalInfo(index: number): void {
    this.additionalInfo.removeAt(index);
  }

  //Fields Array
  get additionalInfo(): FormArray {
    return <FormArray>this.addItineraryForm.get('multiple_itinerarydesc');
  }

  private addAdditionalInfoGroup(): FormGroup {
    return this.formBuilder.group({
      itinerary_title: ['', Validators.required],
    itinerary_desc: ['', Validators.required]
    });
  }

  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
    uploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
    filebrowserBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
    filebrowserImageBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
    filebrowserUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
  };

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.apiHost = environment.API_HOST;
    this.object = this.route.snapshot.paramMap.get('objId');
    if (this.object != undefined && this.object != null && this.object != '') {
      this.edittripthree(this.object);
    }
    this.getdate(this.object);
  }


  addImage(event: any, dayIndex: number) {
    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('uploadFile', files[i], files[i].name);
    }
    this.spinner.show()
    this.apiService.uploadimages(formData).subscribe((result: any) => {
      console.log(result);
      this.spinner.hide()
      const newImages = result.file_data.map((image: any) => this.apiHost + image.src);
      console.log(newImages)
      // Append new images to existing itineraryImages array
      if (this.itineraryImages[dayIndex]) {
        this.itineraryImages[dayIndex] = this.itineraryImages[dayIndex].concat(newImages);
      } else {
        this.itineraryImages[dayIndex] = newImages;
      }
    });
  }
 
removeImage(dayIndex: number, imageIndex: number) {
  this.itineraryImages[dayIndex].splice(imageIndex, 1);
}


  getdate(id) {
    let dataForForm = {
      id: id,
    };
    this.spinner.show()
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      //console.log(result.getData, 'kalu');
    this.spinner.hide();
      // Original date string
      if(result.getData?.DateArr.length !== 0){
        const originalDateString = result.getData?.DateArr[0].start_date;
        const originalDateStringenddate = result.getData?.DateArr[0].end_date;
        // Parse the original date string into a Date object
        const originalDate = new Date(originalDateString);
        const originalDateenddate = new Date(originalDateStringenddate);
        // Extract the year, month, and day components
        const year = originalDate.getFullYear();
        const yearend = originalDateenddate.getFullYear();
        // Months are zero-based, so we add 1 to get the correct month
        const month = originalDate.getMonth() + 1;
        const monthend = originalDateenddate.getMonth() + 1;
        const day = originalDate.getDate();
        const dayend = originalDateenddate.getDate();
        this.formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
        this.formattedDateenddate = `${monthend.toString().padStart(2, '0')}/${dayend.toString().padStart(2, '0')}/${yearend}`;
      }else{
         this.formattedDate =  result.getData?.DateArrCustomize
        [0].start_date_1
       this.formattedDateenddate = result.getData?.DateArrCustomize
       [0].end_date_1
      }
      this.allDates = this.dateRange(
        this.formattedDate,
        this.formattedDateenddate
        //JSON.parse(result.getData.end_date)[0]
      );
      //console.log(this.allDates, result.getData.DateArr[0].start_date, this.allDates, 'lalu');
      this.allDates.forEach((data, i) =>
        this.addItineraryForm.addControl(
          'title' + i,
          new FormControl('', Validators.required)
        )
      );
      this.allDates.forEach((data, i) =>
        this.addItineraryForm.addControl(
          'desc' + i,
          new FormControl('', Validators.required)
        )
      );
      this.allDates.forEach((data, i) => {
        let properties_list = [
          { id: i + '1', name: 'Breakfast', value: 'breakfast', check: false },
          { id: i + '2', name: 'Lunch', value: 'lunch', check: false },
          { id: i + '3', name: 'Dinner', value: 'dinner', check: false },
          { id: i + '4', name: 'Evening Snacks', value: 'evening_snacks', check: false },
          { id: i + '5', name: 'Monument Fee', value: 'monument_fee', check: false },
          { id: i + '6', name: 'Bonfire', value: 'bonfire', check: false },
          { id: i + '7', name: 'DJ Night', value: 'dJ_night', check: false },
          { id: i + '8', name: 'Live Music', value: 'live_music', check: false },
          { id: i + '9', name: 'Movie Screening', value: 'movie_screening', check: false },
          { id: i + '10', name: 'Bike Rent', value: 'bike_rent', check: false },
          { id: i + '11', name: 'Riverrafting', value: 'riverrafting', check: false },
          { id: i + '12', name: 'Paragliding', value: 'paragliding', check: false },
          { id: i + '13', name: 'Water Activities', value: 'water_activities', check: false },
          { id: i + '14', name: 'Jeep Safari', value: 'jeep_safari', check: false },
          { id: i + '15', name: 'Camel Ride', value: 'camel_ride', check: false },
        ];
        this.propertyLists['properties_list_' + i] = properties_list;
        let item = 0;
        do {
          this.addItineraryForm.addControl(
            'properties' + i + (+item),
            new FormControl(false, Validators.required)
          )
          item++;
        } while (item < properties_list.length);
      });
    });
  }


  dateRange(start_date, end_date, steps = 1) {
    console.log(start_date, end_date)
    const dateArray = [];
    let currentDate = new Date(start_date);
    while (currentDate <= new Date(end_date)) {
      dateArray.push(new Date(currentDate));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    console.log(dateArray)
    return dateArray;
  }

  edittripthree(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(this.object);
    this.spinner.show()
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
    console.log(result.getData);
      if (result.success) {
        this.spinner.hide()
        this.itineraryData = result.getData.Itinerary
        console.log(this.itineraryData)
        //this.itineraryImages[dayIndex] = result.file_data.map((image: any) => this.apiHost + image.src);
        this.pricingid = result?.getData?.bookPrice;
        let TripData: any = result.getData;
        this.tripId = TripData._id;
        this.addItineraryForm.patchValue({
          tourOutlineNote: TripData.tourOutlineNote
        });

        TripData.Itinerary.forEach((day, i) => { 
          this.itineraryImages[i] = day.Images.map(image => this.apiHost + 'uploads/' + image);
        });
        // console.log(TripData, 'hiiiiiiiiiiiiiiiiii');
        this.spinner.show();
        setTimeout(() => {
          //<<<---using ()=> syntax
          TripData.Itinerary.forEach((key, i) => {
            this.addItineraryForm.controls['title' + i].setValue(key.Title);
            this.addItineraryForm.controls['desc' + i].setValue(
              key.Description
            );
          });
          this.spinner.hide();
        }, 1000);
        while (this.additionalInfo.length !== 0) {
          this.additionalInfo.removeAt(0);
        }
         const control: any = <FormArray>this.addItineraryForm.controls['multiple_itinerarydesc'];
         // Iterate through the 'multiple_overviewdesc' array and add form groups to the form array
         TripData.multiple_itinerarydesc
         .forEach((element: any) => {
           let fg = this.formBuilder.group({
           
            itinerary_title : [element.itinerary_title, [Validators.required]],
            itinerary_desc: [element.itinerary_desc, [Validators.required]],
           });
           control.push(fg);
         });
        this.spinner.show();
        setTimeout(() => {
          // console.log(TripData.Itinerary[1]?.Properties,'hello');
          for (let i = 0; i < TripData.Itinerary.length; i++) {
            let item = 0;
            do {
              //console.log(this.propertyLists['properties_list_'+i][item]);
              if (TripData.Itinerary[i].Properties.includes(this.propertyLists['properties_list_'+i][item].value)) {
                this.addItineraryForm.controls['properties' + i + (+item)].setValue(true);
              }
              item++;
            } while (item < 15);
            //console.log(this.propertyLists);
            if (TripData.Itinerary[i].Properties.length !== 0) {}
          }
          this.spinner.hide();
        }, 1000);
      }else{
        this.spinner.hide()
      }
    });
  }

  addMakeTourThree(id) {
    for (let i=0; i<this.allDates.length; i++) {
      if (this.addItineraryForm.value[`title${i}`] == '' || 
        this.addItineraryForm.value[`title${i}`] == undefined
      ) {
        Swal.fire({
          text: 'Please enter all itinerary titles',
          icon: 'warning',
        });
        return false;
      }
      if (this.addItineraryForm.value.tourOutlineNote == '' || 
        this.addItineraryForm.value.tourOutlineNote == undefined
      ) {
        Swal.fire({
          text: 'Please enter all Tour Outline Note',
          icon: 'warning',
        });
        return false;
      }
      if (this.addItineraryForm.value[`desc${i}`] == '' || 
        this.addItineraryForm.value[`desc${i}`] == undefined
      ) {
        Swal.fire({
          text: 'Please enter all itinerary descriptions',
          icon: 'warning',
        });
        return false;
      }
    }
    const additionalInfoArray = this.additionalInfo.value;
    console.log('Additional Info Array:', additionalInfoArray);
    let allDateData = [];
    for (let i = 0; i < this.allDates.length; i++) {
      let item = 0;
      let propArr = [];
      do {
        if (this.addItineraryForm.value['properties' + i + (+item)]) {
          propArr.push(this.allProps[item]);
        }
        item++;
      } while (item < 15);
      let images = this.itineraryImages[i] || []; // Extract the image URLs for the current itinerary day
      console.log(images)
      let filenames = images.map(url => url.substring(url.lastIndexOf('/') + 1)); // Extract filenames from URLs
      allDateData.push({
        Title: this.addItineraryForm.value['title' + i],
        Description: this.addItineraryForm.value['desc' + i],
        Properties: propArr,
        Images: filenames 
      });
    }
    this.addItineraryForm.value.Itinerary = allDateData;

    this.addItineraryForm.value.multiple_itinerarydesc = additionalInfoArray; 
    console.log(this.addItineraryForm.value);
    console.log('Form Values:', this.addItineraryForm.value);
    //return
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.spinner.show()
    this.apiService
      .updateTourThree(this.addItineraryForm.value, this.object)
      .subscribe((res: any) => {
        //console.log(this.tripInfo._id)
        //return;
        console.log(res);
        if (res.success) {
          this.spinner.hide()
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.router.navigateByUrl('/make-trip-inclusion/' + this.object);
          console.log(this.object);
        } else {
          this.spinner.hide()
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.error(res.message);
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }
}
